<template>
    <div class="is-input-number" :style="cssProps">
        <div class="is-flex is-align-items-center mb-2">
            <div v-if="!isEmptyStr(label)" class="is-size-6 mr-3">
                {{ label }}
            </div>
            <b-tooltip
                v-if="!isEmptyStr(tooltip)"
                type="is-primary"
                :label="tooltip"
                multilined
            >
                <div
                    class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                >
                    ?
                </div>
            </b-tooltip>
        </div>
        <div class="is-flex pt-2">
            <b-field :type="type" :message="message">
                <b-numberinput
                    size="is-medium"
                    :value="value"
                    :class="{ bigInput: isBig }"
                    :disabled="disabled"
                    :min="min"
                    :max="max"
                    controls-position="compact"
                    :controls="!disabled"
                    @input="$emit('input', $event)"
                    @focus="$emit('focus', $event)"
                ></b-numberinput>
            </b-field>
        </div>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'

export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: '',
        },
        message: {
            type: Array,
            default: null,
        },
        type: {
            type: Object,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Number,
            required: true,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 20,
        },
        isBig: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        cssProps() {
            return {
                '--right-radius': this.disabled ? '4px' : 0,
            }
        },
    },
    methods: {
        isEmptyStr: (str) => utils.isEmptyStr(str),
    },
}
</script>

<style lang="scss">
.bigInput input {
    padding-left: 5rem;
    padding-right: 5rem;
}
.is-input-number {
    .control {
        input {
            border-top-right-radius: var(--right-radius);
            border-bottom-right-radius: var(--right-radius);
        }
    }
}
</style>
