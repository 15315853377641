/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location': {
    width: 15,
    height: 21,
    viewBox: '0 0 15 21',
    data: '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.50006 11.7662C5.69051 11.7662 4.22368 10.1645 4.22368 8.18893C4.22368 6.21317 5.69051 4.61162 7.50006 4.61162C9.30949 4.61162 10.7764 6.21317 10.7764 8.18893C10.7764 10.1645 9.30949 11.7662 7.50006 11.7662ZM7.50006 0C3.35787 0 0 3.66629 0 8.18893C0 16.1537 7.50006 21 7.50006 21C7.50006 21 15 16.1537 15 8.18893C15 3.66629 11.6423 0 7.50006 0Z" fill="white"/>'
  }
})
