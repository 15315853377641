<i18n>
{
    "en-BE": {
        "title": "Helpcenter",
        "cant_find": "Can't find what you're looking for?",
        "contact_cta": "Contact us",
        "contact_form": {
            "title": "Ask for support",
            "content": "If you can't find what you are looking for in the FAQ, we'll be happy to help. Tell us what the problem is and we'll get back to you as soon as possible.",
            "fields": {
                "subject": {
                    "label": "Subject",
                    "values": [
                        "My request has not been evaluated yet",
                        "My request is back under \"Draft\" status but all the information are correct",
                        "I can't download the valuation report of my request",
                        "My request does not appear in my dashboard",
                        "I want to cancel my request"
                    ],
                    "other": "Other"
                },
                "other_subject": {
                    "label": "Fill out your reason"
                },
                "customer_ref": {
                    "label": "Internal reference of the loan",
                    "not_a_specific_request": "This is not about a specific request"
                },
                "message": {
                    "label": "Your question"
                },
                "screenshots": {
                    "label": "Add screenshots",
                    "tip": "Due to security reasons, only files with the following formats can be shared with Rock.estate: .jpg, .jpeg, .png"
                }
            },
            "submit_btn": "Send",
            "feedback_sent": {
                "title": "Thank you for reaching out",
                "content": "We'll be in touch shortly with a solution for your problem or answer to your question.",
                "close_btn": "Close"
            }
        },
        "ing": {
            "contact_form.fields.customer_ref.label": "LOP ID"
        }
    },
    "fr-BE": {
        "title": "Helpcenter",
        "cant_find": "Vous ne trouvez pas ce que vous cherchez?",
        "contact_cta": "Contactez-nous",
        "contact_form": {
            "title": "Demander de l'aide",
            "content": "Si vous ne trouvez pas ce que vous cherchez dans la FAQ, nous serons heureux de vous aider. Dites-nous quel est le problème et nous vous répondrons dans les plus brefs délais.",
            "fields": {
                "subject": {
                    "label": "Sujet",
                    "values": [
                        "Ma demande n'a pas encore été évaluée",
                        "Ma demande est de retour sous le statut de \"À completer\" mais toutes les informations sont correctes.",
                        "Je ne peux pas télécharger le rapport d'évaluation lié à ma demande.",
                        "Ma demande n'apparaît pas dans mon tableau de bord",
                        "Je souhaite annuler ma demande"
                    ],
                    "other": "Autres"
                },
                "other_subject": {
                    "label": "Expliquez votre raison"
                },
                "customer_ref": {
                    "label": "Référence interne de l'emprunt",
                    "not_a_specific_request": " Ce n'est pas au sujet d'une demande spécifique"
                },
                "message": {
                    "label": "Votre question"
                },
                "screenshots": {
                    "label": "Ajouter des captures d'écran",
                    "tip": "Due to security reasons, only files with the following formats can be shared with Rock.estate: .jpg, .jpeg, .png"
                }
            },
            "submit_btn": "Envoyer",
            "feedback_sent": {
                "title": "Merci de nous avoir contacté",
                "content": "Nous vous contacterons sous peu pour trouver une solution à votre problème ou répondre à votre question.",
                "close_btn": "Fermer"
            }
        },
        "ing": {
            "contact_form.fields.customer_ref.label": "LOP ID"
        }
    },
    "nl-BE": {
        "title": "Helpcentrum",
        "cant_find": "Niet gevonden wat je zocht?",
        "contact_cta": "Contacteer ons",
        "contact_form": {
            "title": "Vraag om ondersteuning",
            "content": "Als je in de FAQ niet kan vinden wat je zoekt, helpen wij je graag. Vertel ons wat het probleem is en wij nemen zo spoedig mogelijk contact met je op.",
            "fields": {
                "subject": {
                    "label": "Onderwerp",
                    "values": [
                        "Mijn verzoek is nog niet gewaardeerd",
                        "Mijn verzoek is terug onder \"Ontwerp\" status maar alle informatie is correct",
                        "Ik kan het waarderingsverslag van mijn verzoek niet downloaden",
                        "Mijn verzoek verschijnt niet in mijn Dashboard",
                        "Ik wil mijn verzoek annuleren"
                    ],
                    "other": "Andere"
                },
                "other_subject": {
                    "label": "Vul jouw reden in"
                },
                "customer_ref": {
                    "label": "Interne referentie van de lening",
                    "not_a_specific_request": "Dit gaat niet over een specifiek verzoek"
                },
                "message": {
                    "label": "Jouw vraag"
                },
                "screenshots": {
                    "label": "Voeg schermafbeeldingen toe",
                    "tip": "Due to security reasons, only files with the following formats can be shared with Rock.estate: .jpg, .jpeg, .png"
                }
            },
            "submit_btn": "Verzenden",
            "feedback_sent": {
                "title": "Bedankt om ons te contacteren",
                "content": "Wij zullen binnenkort contact met jou opnemen om een oplossing voor jouw probleem of een antwoord op jouw vraag te vinden.",
                "close_btn": "Sluiten"
            }
        },
        "ing": {
            "contact_form.fields.customer_ref.label": "LOP ID"
        }
    }
}
</i18n>
<template>
    <div class="container section is-fluid">
        <div class="faq-container">
            <h1
                class="title is-size-2 mgt-medium is-family-secondary has-text-weight-bold"
            >
                {{ $t('title') }}
            </h1>

            <b-input
                v-model="searchField"
                placeholder="Search"
                class="mb-6"
                @blur="$v.searchField.$touch()"
                @input="$v.searchField.$touch()"
            ></b-input>

            <div v-for="category in Object.keys(items)" :key="category">
                <h2 class="has-text-primary is-size-5 has-text-weight-medium mgb-small">
                    {{ t(`section.${category}`) }}
                </h2>

                <div class="questions faq-content mb-6">
                    <question
                        v-for="item in items[category]"
                        :id="item.name"
                        :key="item.name"
                        :open="item.shouldOpen"
                        :q-data="{
                            question: $t(`faq.${item.name}_question`),
                            answer: $t(`faq.${item.name}_answer`),
                        }"
                    />
                </div>
            </div>
            <div v-if="shouldShowContact" class="contact-link faq-content mb-6">
                <h2
                    class="has-text-black is-size-5 has-text-weight-medium is-flex is-align-items-center"
                >
                    {{ $t('cant_find') }}
                </h2>
                <b-button size="is-medium" type="is-primary" @click="showContactModal">
                    {{ $t('contact_cta') }}
                </b-button>
            </div>
        </div>
        <b-modal
            v-model="isContactModalActive"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            aria-role="dialog"
            aria-modal
            class="contact-modal"
        >
            <div v-if="!isFeedbackSent" class="exit-modal">
                <button class="closeModalBttn" @click="hideContactModal">
                    <img src="@/assets/icons/bttn-close-black.svg" alt="" />
                </button>
                <h2
                    class="title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                >
                    {{ $t('contact_form.title') }}
                </h2>
                <p class="mb-5">
                    {{ $t('contact_form.content') }}
                </p>
                <form class="contact-form">
                    <b-field :label="$t('contact_form.fields.subject.label')">
                        <b-select v-model="contactForm.subject">
                            <option
                                v-for="(option, index) in $t(
                                    'contact_form.fields.subject.values'
                                )"
                                :key="`subject_option_${index}`"
                                :value="index"
                            >
                                {{ $t(`contact_form.fields.subject.values[${index}]`) }}
                            </option>
                            <option :value="-1">
                                {{ $t('contact_form.fields.subject.other') }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field
                        v-if="contactForm.subject === -1"
                        :label="$t('contact_form.fields.other_subject.label')"
                    >
                        <b-input v-model="contactForm.otherSubject" />
                    </b-field>
                    <b-field :label="t('contact_form.fields.customer_ref.label')">
                        <b-select v-model="contactForm.valuation_request_ref" required>
                            <option
                                v-for="request in userRequests"
                                :key="request.valuation_request_ref"
                                :value="request.valuation_request_ref"
                            >
                                {{ request.customer_ref }} - {{ request.full_address }}
                            </option>
                            <option disabled>-------</option>
                            <option value="N/A">
                                {{
                                    $t(
                                        'contact_form.fields.customer_ref.not_a_specific_request'
                                    )
                                }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field :label="$t('contact_form.fields.message.label')">
                        <b-input v-model="contactForm.content" type="textarea" />
                    </b-field>
                    <div
                        v-for="(file, index) in contactForm.screenshots"
                        :key="`screenshot_${index}`"
                        class="screenshot-item"
                    >
                        <img src="@/assets/icons/ic-file.svg" alt="" class="file-icon" />
                        <span class="screenshot-name">
                            {{ file.name }}
                        </span>
                        <b-button
                            class="delete-button is-light"
                            rounded
                            @click="removeScreenshot(index)"
                        >
                            <b-icon class="file-icon" icon="delete"></b-icon>
                        </b-button>
                    </div>
                    <b-field class="file is-primary" multiple>
                        <b-upload
                            v-model="contactForm.screenshots"
                            class="file-upload"
                            rounded
                            multiple
                            outlined
                            accept=".jpg,.jpeg,.png,image/png,image/jpeg"
                        >
                            <span class="file-cta mt-1">
                                <span class="file-label has-text-weight-bold">
                                    {{ $t('contact_form.fields.screenshots.label') }}
                                </span>
                                <b-icon class="file-icon" icon="image"></b-icon>
                            </span>
                            <h5
                                :class="{
                                    'is-size-7': true,
                                    'has-text-centered': true,
                                    'mt-2': true,
                                    'has-text-danger': isWrongFormat,
                                }"
                            >
                                {{ t('contact_form.fields.screenshots.tip') }}
                            </h5>
                        </b-upload>
                    </b-field>
                </form>

                <span class="separator" />

                <b-button
                    type="is-primary"
                    class="has-text-weight-bold submit-btn"
                    :disabled="isFormSubmitDisabled"
                    @click="sendFeedback"
                >
                    {{ $t('contact_form.submit_btn') }}
                </b-button>
            </div>
            <div v-if="isFeedbackSent" class="exit-modal">
                <button class="closeModalBttn" @click="hideContactModal">
                    <img src="@/assets/icons/bttn-close-black.svg" alt="" />
                </button>
                <h2
                    class="title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                >
                    {{ $t('contact_form.feedback_sent.title') }}
                </h2>
                <p>
                    {{ $t('contact_form.feedback_sent.content') }}
                </p>
                <b-button
                    type="is-primary"
                    class="has-text-weight-bold mt-5"
                    @click="hideContactModal"
                >
                    {{ $t('contact_form.feedback_sent.close_btn') }}
                </b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'
import axios from '@/shared/plugins/axios'
import { localeMixin } from '@/components/mixins/locale.js'
import { config } from './../../assets/config/config'
import Question from './Question.vue'
export default {
    name: 'FAQ',
    components: {
        Question,
    },
    mixins: [localeMixin],
    data() {
        return {
            searchField: '',
            isContactModalActive: false,
            isFeedbackSent: false,

            contactForm: {
                subject: 0,
                valuation_request_ref: '',
                otherSubject: '',
                content: '',
                screenshots: [],
            },

            userRequests: [],
        }
    },
    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Support',
            // all titles will be injected into this template
            titleTemplate: '%s | Rock.estate Valuation App',
        }
    },
    computed: {
        isFormSubmitDisabled() {
            return (
                this.isWrongFormat ||
                utils.isEmptyStr(this.contactForm.valuation_request_ref)
            )
        },
        shouldShowContact() {
            return process.env.VUE_APP_SUPPORT_CONTACT.toLowerCase() === 'true'
        },
        items() {
            let filtered_config = config.filter((item) => {
                var needed = item.functionality === 'faq'
                if (needed && this.searchField && !utils.isEmptyStr(this.searchField)) {
                    needed =
                        this.$t(`faq.${item.name}_question`)
                            .toUpperCase()
                            .includes(this.searchField.toUpperCase()) ||
                        this.$t(`faq.${item.name}_answer`)
                            .toUpperCase()
                            .includes(this.searchField.toUpperCase())

                    item.shouldOpen = false

                    if (
                        needed &&
                        this.$t(`faq.${item.name}_answer`)
                            .toUpperCase()
                            .includes(this.searchField.toUpperCase())
                    ) {
                        item.shouldOpen = true
                    }
                    if (
                        needed &&
                        this.$t(`faq.${item.name}_question`)
                            .toUpperCase()
                            .includes(this.searchField.toUpperCase())
                    ) {
                        item.shouldOpen = false
                    }
                } else if (this.$route.hash.substring(1) === item.name) {
                    item.shouldOpen = true
                } else {
                    item.shouldOpen = false
                }
                return needed
            })

            // Sort by category if needed
            // filtered_config.sort((a, b) => {
            //     if (a.sections[0].toUpperCase() === b.sections[0].toUpperCase()) {
            //         return 0
            //     }

            //     if (
            //         this.t(`section`).hasOwnProperty(a.sections[0]) &&
            //         !this.t(`section`).hasOwnProperty(b.sections[0])
            //     ) {
            //         return 1
            //     }

            //     if (
            //         !this.t(`section`).hasOwnProperty(a.sections[0]) &&
            //         this.t(`section`).hasOwnProperty(b.sections[0])
            //     ) {
            //         return -1
            //     }

            //     return b.sections[0].toUpperCase() > a.sections[0].toUpperCase() ? -1 : 1
            // })
            const categorized = {}

            if (this.searchField && !utils.isEmptyStr(this.searchField)) {
                categorized['faq-search-results'] = [...filtered_config]
            } else {
                filtered_config.forEach((item) => {
                    if (!categorized.hasOwnProperty(item.sections[0])) {
                        categorized[item.sections[0]] = []
                    }

                    categorized[item.sections[0]].push(item)
                })
            }

            return categorized
        },
        isWrongFormat() {
            return (
                this.contactForm.screenshots.length > 0 &&
                !this.contactForm.screenshots.every((x) =>
                    ['image/png', 'image/jpeg'].includes(x.type)
                )
            )
        },
    },
    mounted() {
        this.getRequests()
    },
    methods: {
        showContactModal() {
            if (this.$route.query.valuation_request_ref) {
                this.contactForm.valuation_request_ref = this.$route.query.valuation_request_ref
            }
            this.isContactModalActive = true
            this.isFeedbackSent = false
        },
        hideContactModal() {
            this.isContactModalActive = false
            this.isFeedbackSent = false
        },
        removeScreenshot(index) {
            this.contactForm.screenshots.splice(index, 1)
        },
        resetContactForm() {
            this.contactForm = {
                subject: 0,
                valuation_request_ref: '',
                otherSubject: '',
                content: '',
                screenshots: [],
            }
        },
        getRequests() {
            this.$axios
                .get(
                    utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                        'user',
                        'requests',
                    ])
                )
                .then((res) => {
                    this.userRequests = res.data
                })
        },
        sendFeedback() {
            const {
                valuation_request_ref,
                otherSubject,
                content,
                screenshots,
            } = this.contactForm

            const subject =
                this.contactForm.subject === -1
                    ? otherSubject
                    : this.$t(
                          `contact_form.fields.subject.values[${this.contactForm.subject}]`
                      )

            // TODO : Replace below commented API call to the proper endpoint and
            // remove the lines above this comment block and below the commented
            // API call

            let formData = new FormData()
            formData.append('subject', subject)
            formData.append('valuation_request_ref', valuation_request_ref)
            formData.append(
                'url',
                JSON.stringify({
                    params: this.$route.params,
                    fullPath: this.$route.fullPath,
                    query: this.$route.query,
                    hash: this.$route.hash,
                    name: this.$route.name,
                })
            )
            formData.append('content', content)
            for (let i = 0; i < screenshots.length; i++) {
                formData.append(`screenshot_${i}`, screenshots[i])
            }

            axios
                .post(
                    utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, 'contact'),
                    formData,
                    { headers: { 'content-type': 'multipart/form-data' } }
                )
                .then((res) => {
                    this.isFeedbackSent = true
                    this.resetContactForm()
                })
        },
    },
}
</script>
<style lang="scss" scoped>
@import '@/shared/assets/style/buefy.scss';

.container {
    background: url('../../assets/icons/img-skyline.svg') no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    height: calc(100vh - 53px);
    min-height: calc(100vh - 53px);
    width: 100%;
    overflow: scroll;
}

.faq-container {
    max-width: 800px;
    margin: auto auto;
}

.faq-content {
    border: 1px solid #d6e2e9;
    border-radius: 5px;
    box-shadow: 0px 2px 14px 0px #002f501a;
    background: white;
}

.contact-link {
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contact-modal {
    .exit-modal {
        min-width: 490px;
        max-width: 800px;
        width: fit-content;
        padding: 30px;
        position: relative;
        max-height: 90vh;
        overflow-y: scroll;
        overflow-x: hidden;

        .closeModalBttn {
            all: unset;
            position: absolute;
            top: 30px;
            right: 30px;
            cursor: pointer;
        }
    }
}

.control .select {
    background: red;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .field {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: stretch;
    }

    .screenshot-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;

        .file-icon {
            height: 37px;
            width: auto;
        }

        .screenshot-name {
            flex-grow: 1;
            color: $black;
        }

        .delete-button {
            height: 2.5rem;
            width: 2.5rem;
            background: #99a7cc33;
            color: #99a7cc99;
            padding: 0;

            &:hover {
                background: #99a7cc66;
                color: #99a7ccff;
            }
        }
    }

    .file-upload {
        display: flex;
        flex-direction: column;
        width: 100%;

        .file-cta {
            color: $primary !important;
            border-color: $primary !important;
            background: white !important;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                color: white !important;
                background: $primary !important;
            }

            .icon {
                margin-left: 10px;
            }
        }
    }
}

.submit-btn {
    width: 100%;
}

.separator {
    width: calc(100% + 60px);
    height: 1px;
    background: #e5eaed;
    display: inline-block;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;
}
</style>

<style lang="scss">
.contact-modal {
    .control {
        .select,
        select {
            width: 100% !important;
        }
    }
}
</style>
