/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person': {
    width: 36,
    height: 90,
    viewBox: '0 0 36 90',
    data: '<path d="M27 22.5H9C4.05 22.5 0 26.55 0 31.5V57.5C0 58.0523 0.447715 58.5 1 58.5H9V89C9 89.5523 9.44771 90 10 90H26C26.5523 90 27 89.5523 27 89V58.5H35C35.5523 58.5 36 58.0523 36 57.5V31.5C36 26.55 31.95 22.5 27 22.5Z"/><path d="M18 18C22.9706 18 27 13.9706 27 9C27 4.02944 22.9706 0 18 0C13.0294 0 9 4.02944 9 9C9 13.9706 13.0294 18 18 18Z"/>'
  }
})
