/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'energy': {
    width: 19,
    height: 34,
    viewBox: '0 0 19 34',
    data: '<path class="st0" d="M7,33.9L7,33.9c-0.6,0-1.1-0.6-1.1-1.2l1.7-11.9H1.2c-1.1,0-1.1-0.6-0.7-1.2c0.4-0.6,0.1-0.2,0.1-0.2 C3,15.1,6.4,8.9,11,0.6c0.2-0.3,0.5-0.6,0.9-0.6h0c0.6,0,1.1,0.6,1.1,1.2l-1.7,11.9h6.5c0.9,0,1,0.6,0.9,1l-0.1,0.3 C12.5,25.3,9,31.6,8,33.4C7.8,33.7,7.4,33.9,7,33.9z"/>'
  }
})
