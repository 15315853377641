import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

const getDefaultState = () => {
    return {
        data: {
            address_text: {
                // maybe use names like streetName instead of streetname
                streetname: null,
                streetnumber: null,
                municipality: null,
                postalcode: null,
                boxnumber: null,
                full_address: null,
            },
            features: {
                buildingnotfound: false,
                building_id: null,
                building_ids: [],
                secondary_building_ids: [],
                parcel_ids: [],
                parcels: [],
                f_epc_numeric: null,
                f_epc_label: null,
                f_x: null,
                f_y: null,
                f_lat: null,
                f_lng: null,
                f_building_type: null,
                f_parcel_area: null,
                f_building_area: null,
                f_garden_area: null,
                f_touching_sides: null,
                f_bedrooms: 0,
                f_epc_label: null,
                f_living_area: null,
                is_epc_number: true,
                region: null,
                renovation_cost: 0,
                f_bedrooms: 0,
                f_balcony_present: null,
                f_bottom_floor: 0,
                f_top_floor: 0,
                f_floors: [],
                f_garden_common: null,
                f_garden_private: null,
                f_n_closed_garage: 0,
                f_n_parking_spot: 0,
                f_cellar_attic: null,
            },
            estimateLoaded: false,
            avm_transaction: {
                body: {},
                status: null,
            },
            avm_rental: {
                body: {},
                status: null,
            },
            valuation: {
                date: null,
                market_value: null,
                reconstruction_value: null,
                forced_sale_value: null,
                rental_value: null,
                type: null,
                valuer: null,
            },
            map_scene2d: {
                parcel_geojson: null,
                building_geojson: null,
                address_geojson: null,
                flood_geojson: null,
            },
            map_scene3d: {},
            request: {},
            last_dvm_rejection: { incoherent_features: [] },
            dvm_rejections: [],
            view_options: {
                gsv_available: false, //GoogleStreetView available
                gsv_ph: null, // GoogleStreetView pov heading
                gsv_pp: null, // GoogleStreetView pov pitch
                gsv_pz: null, // GoogleStreetView pov zoom
                gsv_p: null, // GoogleStreetView pano id
                gsv_lat: null,
                gsv_lng: null,
            },
            dvm_features: {
                style_of_house: null,
                exterior_state: null,
                facade_material: [],
                roof_material: [],

                n_roof_windows: 0,
                n_roof_dormers: 0,
                solar_panel_area: 0,

                f_building_listed: false,
                garden_orientation: null,
                outsidePool: false,
                pool_size: 0,
                direct_traffic_level: null,
                indirect_traffic_level: null,
                surroundings: null,
                environment: null,
                other_positive: [],
                other_positive_details: '',
                other_negative: [],
                other_negative_details: '',
                remarks: '',
                internal_remarks: '',
            },
            deviation: {
                style_of_house: 0,
                exterior_state: 0,
                facade_material: 0,
                roof_material: 0,

                n_roof_windows: 0,
                n_roof_dormers: 0,
                solar_panel_area: 0,

                f_building_listed: 0,
                garden_orientation: 0,
                outsidePool: 0,
                pool_size: 0,
                direct_traffic_level: 0,
                indirect_traffic_level: 0,
                surroundings: 0,
                environment: 0,
                other_positive: 0,
                other_negative: 0,
            },
            deviation_override: {
                marketCorrection: 0,
                deviationValue: 0,
            },
        },
    }
}
const state = getDefaultState()
const address = {
    namespaced: true,
    state,
    getters: {
        addressInfo(state) {
            return {
                ...state.data.address_text,
                estimationResultsData: state.data.avm_transaction.body,
            }
        },
        get_epc(state) {
            let f_epc = null
            if (state.data.features.is_epc_number) {
                f_epc = state.data.features.f_epc_numeric
            } else if (state.data.features.region === null) {
                f_epc = null
            } else {
                f_epc =
                    utils.epc_mapping[state.data.features.region][
                        state.data.features.f_epc_label
                    ] || null
            }
            return f_epc
        },
        getFeatures(state, getters) {
            let f_number_of_facades = null
            if (state.data.features.f_touching_sides === 0) {
                f_number_of_facades = 'detached'
            } else if (state.data.features.f_touching_sides === 1) {
                f_number_of_facades = 'semi'
            } else if (state.data.features.f_touching_sides >= 2) {
                f_number_of_facades = 'attached'
            }
            return {
                ...state.data.features,
                f_epc: getters.get_epc,
                f_number_of_facades: f_number_of_facades,
            }
        },
        getDVMFeatures(state) {
            return state.data.dvm_features
        },
        getEstimate(state) {
            return state.data.estimateLoaded
        },
        getFullBuildingData(state, getters) {
            let data = {
                ...state.data,
            }
            data.features.f_epc = getters.get_epc
            return data
        },
        getValuation(state) {
            let valuation = { ...state.data.valuation }
            valuation.package_type =
                state.data.features.renovation_cost >= 10000
                    ? 'renovation_light'
                    : 'classic'
            if (
                valuation.market_value === null &&
                state.data.avm_transaction.status == 200
            ) {
                valuation.type = 'avm'
                valuation.market_value =
                    Math.round(
                        state.data.avm_transaction.body.estimation.asking_price_q50 / 1000
                    ) * 1000
                valuation.valuer = '-'
                valuation.date = new Date(Date.now())
                if (state.data.avm_rental.status == 200) {
                    valuation.rental_value =
                        Math.round(
                            state.data.avm_rental.body.estimation.asking_price_q50 / 10
                        ) * 10
                }
            }
            if (valuation.package_type === 'renovation_light') {
                if (valuation.date < new Date(2022, 5, 10, 0, 0, 0)) {
                    valuation.market_value_post_renovation =
                        valuation.market_value + state.data.features.renovation_cost / 4
                    valuation.market_value =
                        valuation.market_value - state.data.features.renovation_cost / 2
                } else {
                    valuation.market_value_post_renovation =
                        valuation.market_value +
                        (state.data.features.renovation_cost * 3) / 4
                }
            }
            return valuation
        },
        getView(state) {
            return state.data.view_options
        },
        errorMessage(state) {
            if (state.data.avm_transaction.body.errors.length > 0) {
                return state.data.avm_transaction.body.errors[0].message
            } else {
                return null
            }
        },
        get_map_scene2d(state) {
            return state.data.map_scene_2d
                ? state.data.map_scene_2d
                : state.data.map_scene2d
        },
        get_map_scene3d(state) {
            let map_scene3d = { ...state.data.map_scene3d }
            map_scene3d.attributions = []
            return map_scene3d
        },
    },
    mutations: {
        SET_ADDRESS_TEXT(state, address) {
            Object.assign(state.data.address_text, address)
            let box_appendix = state.data.address_text.boxnumber
                ? ` b ${state.data.address_text.boxnumber}`
                : ''
            if (state.data.address_text.postalcode !== null) {
                state.data.address_text.full_address = `${state.data.address_text.streetname} ${state.data.address_text.streetnumber}${box_appendix}, ${state.data.address_text.postalcode}`
            } else {
                state.data.address_text.full_address = ''
            }
        },
        SET_FEATURES(state, features) {
            Object.assign(state.data.features, features)
        },
        SET_DVM_FEATURES(state, dvm_features) {
            Object.assign(state.data.dvm_features, dvm_features)
        },
        SET_DEVIATIONS(state, deviation) {
            Object.assign(state.data.deviation, deviation)
        },
        SET_DEVIATION_OVERRIDE(state, deviation_override) {
            Object.assign(state.data.deviation_override, deviation_override)
        },
        SET_REJECTIONS(state, rejections) {
            state.data.dvm_rejections = rejections
        },
        SET_AVM(state, avm_results) {
            Object.assign(state.data.avm_transaction, avm_results.avm_transaction)
            Object.assign(state.data.avm_rental, avm_results.avm_rental)
        },
        SET_MAP_SCENE2D(state, map_scene2d) {
            state.data.map_scene2d = getDefaultState().data.map_scene2d
            Object.assign(state.data.map_scene2d, map_scene2d)
            state.data.map_scene2d.map_data_ready =
                state.data.map_scene2d.building_geojson !== null &&
                state.data.map_scene2d.parcel_geojson !== null &&
                state.data.map_scene2d.address_geojson !== null
        },
        SET_MAP_SCENE3D(state, map_scene3d) {
            state.data.map_scene3d = map_scene3d
        },
        SET_LAST_DVM_REJECTION(state, last_dvm_rejection) {
            Object.assign(state.data.last_dvm_rejection, last_dvm_rejection)
        },
        SET_VALUATION(state, valuation) {
            Object.assign(state.data.valuation, valuation)
        },
        SET_REQUEST(state, request) {
            Object.assign(state.data.request, request)
        },
        SET_VIEW(state, view) {
            state.data.view_options = view
        },
        RESET_FEATURES(state) {
            for (const feature of Object.keys(state.data.features)) {
                if (feature !== 'f_building_type')
                    state.data.features[feature] =
                        typeof getDefaultState().data.features[feature] !== 'undefined'
                            ? getDefaultState().data.features[feature]
                            : null
            }
        },
        RESET_MAP(state) {
            state.data.map_scene2d = getDefaultState().data.map_scene2d
            state.data.avm_transaction = getDefaultState().data.avm_transaction
            state.data.avm_rental = getDefaultState().data.avm_rental
        },
        RESET_FULL_DATA(state) {
            Object.assign(state, getDefaultState())
        },
        SELECT_MAIN_BUILDING_ID(state, building_id) {
            state.data.features.building_id = building_id
            state.data.features.building_ids = [building_id]
            // Remove the main buildings from secondary buildings if applicable
            var index = state.data.features.secondary_building_ids.indexOf(building_id)
            if (index !== -1) {
                state.data.features.secondary_building_ids.splice(index, 1)
            }
        },
        TOGGLE_SECONDARY_BUILDING_ID(state, building_id) {
            // Never set the main building as a secondary building
            if (state.data.features.building_id === building_id) return
            var index = state.data.features.secondary_building_ids.indexOf(building_id)
            if (index === -1) {
                state.data.features.secondary_building_ids.push(building_id)
            } else {
                state.data.features.secondary_building_ids.splice(index, 1)
            }
        },
        SET_PARCEL_IDS(state, parcel_ids) {
            state.data.features.parcel_ids = parcel_ids
            let parcel_features = parcel_ids.map((id) =>
                state.data.map_scene2d.parcel_geojson.features.find(
                    (parcel) => parcel.properties.parcel_id === id
                )
            )
            state.data.features.f_parcel_area = parcel_features
                .map((p) => p.properties.parcel_area)
                .reduce((a, b) => a + b, 0)
        },
        SELECT_PARCELS(state, parcels) {
            state.data.features.parcel_ids = parcels.map(
                (parcel) => parcel.properties.parcel_id
            )
            state.data.features.f_parcel_area = state.data.features.parcels
                .map((p) => p.properties.parcel_area)
                .reduce((a, b) => a + b, 0)
        },
        TOGGLE_PARCEL(state, parcel) {
            let parcel_id = parcel.properties.parcel_id
            var index = state.data.features.parcel_ids.indexOf(parcel_id)
            if (index === -1) {
                state.data.features.parcel_ids.push(parcel_id)
                state.data.features.parcels.push(parcel)
            } else {
                state.data.features.parcel_ids.splice(index, 1)
                state.data.features.parcels.splice(index, 1)
            }
            state.data.features.f_parcel_area = state.data.features.parcels
                .map((p) => p.properties.parcel_area)
                .reduce((a, b) => a + b, 0)
        },
        SET_ESTIMATION_STATUS(state, status) {
            state.data.estimateLoaded = status
        },
        UPDATE_DVM_REJECTIONS(state, rejection) {
            if (!state.data.dvm_rejections) {
                state.data.dvm_rejections = [rejection]
            } else {
                state.data.dvm_rejections.push(rejection)
            }
        },
    },
    actions: {
        fetch_map_data(context) {
            return axios
                .get('/map/scene2d', {
                    params: {
                        building_id: context.state.data.features.building_id,
                    },
                })
                .then((response) => {
                    context.commit('SET_MAP_SCENE2D', response.data)
                })
        },
        auto_select_parcels(context) {
            for (
                let i = 0;
                i < context.state.data.map_scene2d.building_geojson.features.length;
                i++
            ) {
                if (
                    context.state.data.map_scene2d.building_geojson.features[i].properties
                        .building_id == context.state.data.features.building_id
                ) {
                    let parcel_ids =
                        context.state.data.map_scene2d.building_geojson.features[i]
                            .properties.parcel_ids
                    context.commit('SET_PARCEL_IDS', parcel_ids)
                }
            }
            return new Promise((res, rej) => {
                res()
            })
        },
        match_address(context) {
            return axios
                .get('/features', {
                    params: {
                        streetnumber: context.state.data.address_text.streetnumber,
                        streetname: context.state.data.address_text.streetname,
                        postalcode: context.state.data.address_text.postalcode,
                        minimum_level: '0',
                    },
                })
                .then((response) => {
                    context.commit('RESET_FEATURES')
                    context.commit('RESET_MAP')
                    context.commit('SET_FEATURES', {
                        region: response.data.region,
                        level: response.data.level,
                        building_id: response.data.building_id,
                        building_ids: response.data.building_ids || [],
                        f_lat: response.data.f_lat,
                        f_lng: response.data.f_lng,
                    })
                })
        },
        fetch_features(context) {
            return axios
                .get(`/features/${context.state.data.features.building_id}`)
                .then((response) => {
                    response.data.f_building_type =
                        context.state.data.features.f_building_type
                    response.data.building_id = context.state.data.features.building_id
                    response.data.building_ids = context.state.data.features.building_ids
                    response.data.parcel_ids = context.state.data.features.parcel_ids
                    response.data.f_parcel_area =
                        context.state.data.features.f_parcel_area
                    response.data.region = context.state.data.features.region
                    context.commit('RESET_FEATURES')
                    context.commit('SET_FEATURES', response.data)
                })
        },
        fetchStreetviewInfo(context) {
            return axios
                .get('/meta/streetview', {
                    params: {
                        lat: context.state.data.features.f_lat,
                        lng: context.state.data.features.f_lng,
                    },
                })
                .then((response) => {
                    context.commit('SET_VIEW', {
                        gsv_available: response.status === 200,
                        gsv_ph: response.data.heading ? response.data.heading : null,
                        gsv_pp: 0,
                        gsv_pz: 1,
                        gsv_p: response.data.pano_id ? response.data.pano_id : null,
                        gsv_lat: response.data.lat ? response.data.lat : null,
                        gsv_lng: response.data.lng ? response.data.lng : null,
                    })
                })
                .catch((error) => {
                    context.commit('SET_VIEW', getDefaultState().data.view_options)
                })
        },
    },
}

export default address
