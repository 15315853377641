<i18n>
{
    "en-BE":{
        "environment": {
            "title": "Environment",
            "below": "Below average",
            "avg": "Average",
            "above": "Above average"
        },
        "total_deviation": "Total Deviation",
        "avm_features_deviation": "AVM Features Deviation",
        "market_correction": "Market Correction",
        "dvm_adjustment": "DVM Adjustment",
        "apply_deviation": "Apply Features Deviation",
        "update_remarks": "Update Internal Remarks with Deviations",
        "overridden_deviation": "Overridden Deviation"
    },
    "fr-BE":{
        "environment": {
            "title": "Environnement",
            "below": "Sous la moyenne",
            "avg": "Dans la moyenne",
            "above": "Au dessus de la moyenne"
        },
        "total_deviation": "Déviation totale",
        "avm_features_deviation": "Déviation des critères AVM",
        "market_correction": "Correction marchande",
        "dvm_adjustment": "Ajustement DVM",
        "apply_deviation": "Appliquer les ajustements",
        "update_remarks": "Ajouter les ajustements aux remarques internes",
        "overridden_deviation": "Deviation manuelle"
    },
    "nl-BE":{
        "environment": {
            "title": "Environment",
            "below": "Below average",
            "avg": "Average",
            "above": "Above average"
        },
        "total_deviation": "Total Deviation",
        "avm_features_deviation": "AVM Features Deviation",
        "market_correction": "Market Correction",
        "dvm_adjustment": "DVM Adjustment",
        "apply_deviation": "Apply Features Deviation",
        "update_remarks": "Update Internal Remarks with Deviations",
        "overridden_deviation": "Overridden Deviation"
    }
}
</i18n>
<template>
    <div>
        <h1 class="is-size-4 has-text-black has-text-weight-bold">DVM</h1>
        <div class="is-w100 is-grid">
            <h3 class="has-text-right">
                {{ $t('dvm_features.style_of_house') }}
            </h3>
            <div class="is-flex button-group">
                <b-radio-button
                    v-model="dvm_features.style_of_house"
                    size="is-small"
                    type="is-primary"
                    native-value="traditional"
                >
                    <span>{{ $t('value.traditional') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.style_of_house"
                    size="is-small"
                    type="is-primary"
                    native-value="modern"
                >
                    <span>{{ $t('value.modern') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.style_of_house"
                    size="is-small"
                    type="is-primary"
                    native-value="contemporary"
                >
                    <span>{{ $t('value.contemporary') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.style_of_house"
                    size="is-small"
                    type="is-primary"
                    native-value="mediterranean"
                >
                    <span>{{ $t('value.mediterranean') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.style_of_house"
                    size="is-small"
                    type="is-primary"
                    native-value="other"
                >
                    <span>{{ $t('value.other') }}</span>
                </b-radio-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation.style_of_house"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">
                {{ $t('dvm_features.exterior_state') }}
            </h3>
            <div class="is-flex button-group">
                <b-radio-button
                    v-model="dvm_features.exterior_state"
                    size="is-small"
                    type="is-primary"
                    native-value="maintenance_required"
                >
                    <span>{{ $t('value.maintenance_required') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.exterior_state"
                    size="is-small"
                    type="is-primary"
                    native-value="good"
                >
                    <span>{{ $t('value.good') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.exterior_state"
                    size="is-small"
                    type="is-primary"
                    native-value="very_good"
                >
                    <span>{{ $t('value.very_good') }}</span>
                </b-radio-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation.exterior_state"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">
                {{ $t('dvm_features.facade_material') }}
            </h3>
            <div class="is-flex button-group">
                <b-checkbox-button
                    v-model="dvm_features.facade_material"
                    size="is-small"
                    type="is-primary"
                    native-value="brick"
                >
                    <span>{{ $t('value.brick') }}</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.facade_material"
                    size="is-small"
                    type="is-primary"
                    native-value="cinder_blocks"
                >
                    <span>{{ $t('value.cinder_blocks') }}</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.facade_material"
                    size="is-small"
                    type="is-primary"
                    native-value="wood"
                >
                    <span>{{ $t('value.wood') }}</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.facade_material"
                    size="is-small"
                    type="is-primary"
                    native-value="plaster"
                >
                    <span>{{ $t('value.plaster') }}</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.facade_material"
                    size="is-small"
                    type="is-primary"
                    native-value="natural_stone"
                >
                    <span>{{ $t('value.natural_stone') }}</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.facade_material"
                    size="is-small"
                    type="is-primary"
                    native-value="metal"
                >
                    <span>{{ $t('value.metal') }}</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.facade_material"
                    size="is-small"
                    type="is-primary"
                    native-value="concrete"
                >
                    <span>{{ $t('value.concrete') }}</span>
                </b-checkbox-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation.facade_material"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">
                {{ $t('dvm_features.roof_material') }}
            </h3>
            <div class="is-flex button-group">
                <b-checkbox-button
                    v-model="dvm_features.roof_material"
                    size="is-small"
                    type="is-primary"
                    native-value="tiles"
                >
                    <span>{{ $t('value.tiles') }}</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.roof_material"
                    size="is-small"
                    type="is-primary"
                    native-value="bitumen_roofing"
                >
                    <span>{{ $t('value.bitumen_roofing') }}</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.roof_material"
                    size="is-small"
                    type="is-primary"
                    native-value="metal"
                >
                    <span>{{ $t('value.metal') }}</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.roof_material"
                    size="is-small"
                    type="is-primary"
                    native-value="slate_shingles"
                >
                    <span>{{ $t('value.slate_shingles') }}</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.roof_material"
                    size="is-small"
                    type="is-primary"
                    native-value="black_concrete_tiles"
                >
                    <span>{{ $t('value.black_concrete_tiles') }}</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.roof_material"
                    size="is-small"
                    type="is-primary"
                    native-value="thatched"
                >
                    <span>{{ $t('value.thatched') }}</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.roof_material"
                    size="is-small"
                    type="is-primary"
                    native-value="asbestos"
                >
                    <span>{{ $t('value.asbestos') }}</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.roof_material"
                    size="is-small"
                    type="is-primary"
                    native-value="green_roof"
                >
                    <span>{{ $t('value.green_roof') }}</span>
                </b-checkbox-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation.roof_material"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 v-if="!isApartment" class="has-text-right">
                {{ $t('dvm_features.n_roof_windows') }}
            </h3>
            <b-input
                v-if="!isApartment"
                v-model="dvm_features.n_roof_windows"
                type="number"
                size="is-small"
            />
            <div v-if="!isApartment" class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation.n_roof_windows"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 v-if="!isApartment" class="has-text-right">
                {{ $t('dvm_features.n_roof_dormers') }}
            </h3>
            <b-input
                v-if="!isApartment"
                v-model="dvm_features.n_roof_dormers"
                type="number"
                size="is-small"
            />
            <div v-if="!isApartment" class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation.n_roof_dormers"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 v-if="!isApartment" class="has-text-right">
                {{ $t('dvm_features.solar_panel_area') }}
            </h3>
            <b-input
                v-if="!isApartment"
                v-model="dvm_features.solar_panel_area"
                type="number"
                size="is-small"
            />
            <div v-if="!isApartment" class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation.solar_panel_area"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">
                {{ $t('dvm_features.f_building_listed') }}
            </h3>
            <div class="is-flex button-group">
                <b-radio-button
                    v-model="dvm_features.f_building_listed"
                    size="is-small"
                    type="is-primary"
                    :native-value="true"
                >
                    <span>{{ $t('value.yes') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.f_building_listed"
                    size="is-small"
                    type="is-primary"
                    :native-value="false"
                >
                    <span>{{ $t('value.no') }}</span>
                </b-radio-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation.f_building_listed"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">
                {{ $t('dvm_features.garden_orientation') }}
            </h3>
            <div class="is-flex button-group">
                <b-radio-button
                    v-model="dvm_features.garden_orientation"
                    size="is-small"
                    type="is-primary"
                    native-value="n"
                >
                    <span>{{ $t('value.n') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.garden_orientation"
                    size="is-small"
                    type="is-primary"
                    native-value="ne"
                >
                    <span>{{ $t('value.ne') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.garden_orientation"
                    size="is-small"
                    type="is-primary"
                    native-value="e"
                >
                    <span>{{ $t('value.e') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.garden_orientation"
                    size="is-small"
                    type="is-primary"
                    native-value="se"
                >
                    <span>{{ $t('value.se') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.garden_orientation"
                    size="is-small"
                    type="is-primary"
                    native-value="s"
                >
                    <span>{{ $t('value.s') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.garden_orientation"
                    size="is-small"
                    type="is-primary"
                    native-value="sw"
                >
                    <span>{{ $t('value.sw') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.garden_orientation"
                    size="is-small"
                    type="is-primary"
                    native-value="w"
                >
                    <span>{{ $t('value.w') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.garden_orientation"
                    size="is-small"
                    type="is-primary"
                    native-value="nw"
                >
                    <span>{{ $t('value.nw') }}</span>
                </b-radio-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation.garden_orientation"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">
                {{ $t('dvm_features.f_swimming_pool') }}
            </h3>
            <div class="is-flex button-group">
                <b-radio-button
                    v-model="dvm_features.outsidePool"
                    size="is-small"
                    type="is-primary"
                    :native-value="true"
                >
                    <span>{{ $t('value.yes') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.outsidePool"
                    size="is-small"
                    type="is-primary"
                    :native-value="false"
                >
                    <span>{{ $t('value.no') }}</span>
                </b-radio-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input v-model="deviation.outsidePool" type="number" size="is-small" />
                <span class="ml-1">%</span>
            </div>
            <h3 v-if="dvm_features.outsidePool" class="has-text-right">
                {{ $t('dvm_features.f_swimming_pool_area') }}
            </h3>
            <b-input
                v-if="dvm_features.outsidePool"
                v-model="dvm_features.pool_size"
                size="is-small"
            />
            <div v-if="dvm_features.outsidePool" />
            <h3 class="has-text-right">
                {{ $t('dvm_features.direct_traffic_level') }}
            </h3>
            <div class="is-flex button-group">
                <b-radio-button
                    v-model="dvm_features.direct_traffic_level"
                    size="is-small"
                    type="is-primary"
                    native-value="low"
                >
                    <span>{{ $t('value.low') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.direct_traffic_level"
                    size="is-small"
                    type="is-primary"
                    native-value="medium"
                >
                    <span>{{ $t('value.medium') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.direct_traffic_level"
                    size="is-small"
                    type="is-primary"
                    native-value="high"
                >
                    <span>{{ $t('value.high') }}</span>
                </b-radio-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation.direct_traffic_level"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">
                {{ $t('dvm_features.indirect_traffic_level') }}
            </h3>
            <div class="is-flex button-group">
                <b-radio-button
                    v-model="dvm_features.indirect_traffic_level"
                    size="is-small"
                    type="is-primary"
                    native-value="low"
                >
                    <span>{{ $t('value.low') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.indirect_traffic_level"
                    size="is-small"
                    type="is-primary"
                    native-value="medium"
                >
                    <span>{{ $t('value.medium') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.indirect_traffic_level"
                    size="is-small"
                    type="is-primary"
                    native-value="high"
                >
                    <span>{{ $t('value.high') }}</span>
                </b-radio-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation.indirect_traffic_level"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">
                {{ $t('dvm_features.surroundings') }}
            </h3>
            <div class="is-flex button-group">
                <b-radio-button
                    v-model="dvm_features.surroundings"
                    size="is-small"
                    type="is-primary"
                    native-value="urban"
                >
                    <span>{{ $t('value.urban') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.surroundings"
                    size="is-small"
                    type="is-primary"
                    native-value="rural"
                >
                    <span>{{ $t('value.rural') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.surroundings"
                    size="is-small"
                    type="is-primary"
                    native-value="isolated"
                >
                    <span>{{ $t('value.isolated') }}</span>
                </b-radio-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input v-model="deviation.surroundings" type="number" size="is-small" />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">
                {{ $t('dvm_features.other_positive') }}
            </h3>
            <div class="is-flex button-group">
                <b-checkbox-button
                    v-model="dvm_features.other_positive"
                    native-value="classified_facade"
                    size="is-small"
                >
                    {{ $t('value.classified_facade') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_positive"
                    native-value="green_neighbourhood"
                    size="is-small"
                >
                    {{ $t('value.green_neighbourhood') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_positive"
                    native-value="sea_view"
                    size="is-small"
                >
                    {{ $t('value.sea_view') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_positive"
                    native-value="no_direct_traffic"
                    size="is-small"
                >
                    {{ $t('value.no_direct_traffic') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_positive"
                    native-value="exceptional_view"
                    size="is-small"
                >
                    {{ $t('value.exceptional_view') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_positive"
                    native-value="other"
                    size="is-small"
                >
                    {{ $t('value.other') }}
                </b-checkbox-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation.other_positive"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3
                v-if="dvm_features.other_positive.includes['other']"
                class="has-text-right"
            >
                {{ $t('value.other') }}:
            </h3>
            <b-input
                v-if="dvm_features.other_positive.includes['other']"
                v-model="dvm_features.other_positive_details"
                size="is-small"
            />
            <div v-if="dvm_features.other_positive.includes['other']" />
            <h3 class="has-text-right">
                {{ $t('dvm_features.other_negative') }}
            </h3>
            <div class="is-flex button-group">
                <b-checkbox-button
                    v-model="dvm_features.other_negative"
                    native-value="tram"
                    size="is-small"
                >
                    {{ $t('value.tram') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_negative"
                    native-value="train"
                    size="is-small"
                >
                    {{ $t('value.train') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_negative"
                    native-value="metro"
                    size="is-small"
                >
                    {{ $t('value.metro') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_negative"
                    native-value="traffic"
                    size="is-small"
                >
                    {{ $t('value.traffic') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_negative"
                    native-value="busy"
                    size="is-small"
                >
                    {{ $t('value.busy_area') }}
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="dvm_features.other_negative"
                    native-value="other"
                    size="is-small"
                >
                    {{ $t('value.other') }}
                </b-checkbox-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation.other_negative"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3
                v-if="dvm_features.other_negative.includes['other']"
                class="has-text-right"
            >
                {{ $t('value.other') }}:
            </h3>
            <b-input
                v-if="dvm_features.other_negative.includes['other']"
                v-model="dvm_features.other_negative_details"
                size="is-small"
            />
            <div v-if="dvm_features.other_negative.includes['other']" />
            <h3 class="has-text-right">
                {{ $t('environment.title') }}
            </h3>
            <div class="is-flex button-group">
                <b-radio-button
                    v-model="dvm_features.environment"
                    size="is-small"
                    type="is-primary"
                    native-value="below-avg"
                >
                    <span>{{ $t('environment.below') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.environment"
                    size="is-small"
                    type="is-primary"
                    native-value="avg"
                >
                    <span>{{ $t('environment.avg') }}</span>
                </b-radio-button>
                <b-radio-button
                    v-model="dvm_features.environment"
                    size="is-small"
                    type="is-primary"
                    native-value="above-avg"
                >
                    <span>{{ $t('environment.above') }}</span>
                </b-radio-button>
            </div>
            <div class="is-flex is-align-items-center">
                <b-input v-model="deviation.environment" type="number" size="is-small" />
                <span class="ml-1">%</span>
            </div>
            <div />
            <h3 class="has-text-right">{{ $t('total_deviation') }}</h3>
            <div class="is-flex is-align-items-center">
                <b-input :value="totalDeviation" type="number" size="is-small" disabled />
                <span class="ml-1">%</span>
            </div>
            <div />
            <h3 class="has-text-right">{{ $t('avm_features_deviation') }}</h3>
            <div class="is-flex is-align-items-center">
                <b-input :value="avmDeviation" type="number" size="is-small" disabled />
                <span class="ml-1">%</span>
            </div>
            <div />
            <h3 class="has-text-right">{{ $t('market_correction') }}</h3>
            <div class="is-flex is-align-items-center">
                <b-input
                    v-model="deviation_override.marketCorrection"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </div>
            <h3 class="has-text-right">{{ $t('dvm_adjustment') }}</h3>
            <div class="is-flex is-align-items-center">
                <b-button
                    type="is-primary"
                    size="is-small"
                    class="mr-2"
                    @click="applyDeviation"
                >
                    {{ $t('apply_deviation') }}
                </b-button>
                <b-slider
                    :value="+deviation_override.deviationValue"
                    size="is-small"
                    type="is-primary"
                    :min="-100"
                    :max="200"
                    @change="setDeviation"
                    @dragging="setDeviation"
                />
            </div>
            <b-field type="is-black" class="is-flex is-align-items-center mb-0">
                <b-input
                    v-model="deviation_override.deviationValue"
                    type="number"
                    size="is-small"
                />
                <span class="ml-1">%</span>
            </b-field>
            <div />
            <h3 class="has-text-right">{{ $t('features.transaction_value') }}</h3>
            <b-input size="is-small" :value="withSpaces(transaction_price)" disabled />
            <div />
            <h3 class="has-text-right">{{ $t('valuation.forced_sale_value') }}</h3>
            <b-input size="is-small" :value="withSpaces(forced_sale)" disabled />
            <div />
            <h3 class="has-text-right">{{ $t('valuation.rental_value') }}</h3>
            <b-input size="is-small" :value="withSpaces(rental_value)" disabled />
            <div />
            <h3 class="has-text-right">{{ $t('valuation.reconstruction_value') }}</h3>
            <b-input
                size="is-small"
                :value="withSpaces(prices.reconstruction_value)"
                disabled
            />
            <b-button
                size="is-small"
                type="is-primary"
                class="update-remarks-button mb-2 is-full-grid-width"
                @click="setInternalRemarks"
            >
                {{ $t('update_remarks') }}
            </b-button>
        </div>
        <b-field :label="$t('dvm_features.remarks')" class="mb-4 mx-0 valuer-remarks">
            <b-input v-model="dvm_features.remarks" type="textarea" rows="6" lazy />
        </b-field>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'DesktopValue',
    props: {
        features: {
            type: Object,
            required: true,
        },
        prices: {
            type: Object,
            required: true,
        },
        updateRemarks: {
            type: Function,
            required: true,
        },
        avmDeviation: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            dvm_features: {
                style_of_house: null,
                exterior_state: null,
                facade_material: [],
                roof_material: [],

                n_roof_windows: 0,
                n_roof_dormers: 0,
                solar_panel_area: 0,

                f_building_listed: false,
                garden_orientation: null,
                outsidePool: false,
                pool_size: 0,
                direct_traffic_level: null,
                indirect_traffic_level: null,
                surroundings: null,
                environment: null,
                other_positive: [],
                other_positive_details: '',
                other_negative: [],
                other_negative_details: '',
                remarks: '',
            },
            deviation: {
                style_of_house: 0,
                exterior_state: 0,
                facade_material: 0,
                roof_material: 0,

                n_roof_windows: 0,
                n_roof_dormers: 0,
                solar_panel_area: 0,

                f_building_listed: 0,
                garden_orientation: 0,
                outsidePool: 0,
                pool_size: 0,
                direct_traffic_level: 0,
                indirect_traffic_level: 0,
                surroundings: 0,
                environment: 0,
                other_positive: 0,
                other_negative: 0,
            },
            deviation_override: {
                marketCorrection: 0,
                deviationValue: 0,
            },
        }
    },
    computed: {
        ...mapGetters('Address', ['getDVMFeatures']),
        isApartment() {
            return this.features?.f_building_type === 'apartment'
        },
        totalDeviation() {
            let dev = 0
            for (const [key, value] of Object.entries(this.deviation)) {
                dev += +value
            }
            return dev
        },
        transaction_price() {
            return (
                Math.round(
                    (this.prices.transaction_price *
                        (1 + this.deviation_override.deviationValue / 100)) /
                        1000
                ) * 1000
            )
        },
        forced_sale() {
            return (
                Math.round(
                    (this.prices.forced_sale *
                        (1 + this.deviation_override.deviationValue / 100)) /
                        1000
                ) * 1000
            )
        },
        rental_value() {
            return (
                Math.round(
                    (this.prices.rental_value *
                        (1 + this.deviation_override.deviationValue / 100)) /
                        10
                ) * 10
            )
        },
    },
    watch: {
        dvm_features: {
            deep: true,
            handler(val) {
                this.SET_DVM_FEATURES(val)
            },
        },
        deviation: {
            deep: true,
            handler(val) {
                this.SET_DEVIATIONS(val)
                this.SET_VALUATION({
                    market_value: this.transaction_price,
                    reconstruction_value: this.prices.reconstruction_value,
                    forced_sale_value: this.forced_sale,
                    rental_value: this.rental_value,
                })
            },
        },
        deviation_override: {
            deep: true,
            handler(val) {
                this.SET_DEVIATION_OVERRIDE(val)
                this.SET_VALUATION({
                    market_value: this.transaction_price,
                    reconstruction_value: this.prices.reconstruction_value,
                    forced_sale_value: this.forced_sale,
                    rental_value: this.rental_value,
                })
            },
        },
    },
    mounted() {
        this.dvm_features = this.getDVMFeatures
    },
    methods: {
        ...mapMutations('Address', [
            'SET_DVM_FEATURES',
            'SET_DEVIATIONS',
            'SET_DEVIATION_OVERRIDE',
            'SET_VALUATION',
        ]),
        withSpaces(number) {
            return utils.numberWithSpaces(number)
        },
        toggleElement(category, element) {
            this[category][element] = !this[category][element]
        },
        applyDeviation() {
            this.deviation_override.deviationValue =
                this.totalDeviation +
                +this.deviation_override.marketCorrection +
                +this.avmDeviation
        },
        setDeviation(value) {
            this.deviation_override.deviationValue = +value
        },
        setInternalRemarks() {
            let remarks = ''

            if (this.dvm_features.style_of_house) {
                remarks += `${this.$t('dvm_features.style_of_house')}: ${this.$t(
                    'value.' + this.dvm_features.style_of_house
                )} => ${this.deviation.style_of_house}%`
                remarks += '\n'
            }

            if (this.dvm_features.exterior_state) {
                remarks += `${this.$t('dvm_features.exterior_state')}: ${this.$t(
                    'value.' + this.dvm_features.exterior_state
                )} => ${this.deviation.exterior_state}%`
                remarks += '\n'
            }

            if (this.dvm_features.facade_material) {
                remarks += `${this.$t('dvm_features.facade_material')}: ${this.$t(
                    'value.' + this.dvm_features.facade_material
                )} => ${this.deviation.facade_material}%`
                remarks += '\n'
            }

            if (this.dvm_features.roof_material) {
                remarks += `${this.$t('dvm_features.roof_material')}: ${this.$t(
                    'value.' + this.dvm_features.roof_material
                )} => ${this.deviation.roof_material}%`
                remarks += '\n'
            }

            if (!this.isApartment) {
                remarks += `${this.$t('dvm_features.n_roof_windows')}: ${
                    this.dvm_features.n_roof_windows
                } => ${this.deviation.n_roof_windows}%`
                remarks += '\n'
                remarks += `${this.$t('dvm_features.n_roof_dormers')}: ${
                    this.dvm_features.n_roof_dormers
                } => ${this.deviation.n_roof_dormers}%`
                remarks += '\n'
                remarks += `${this.$t('dvm_features.solar_panel_area')}: ${
                    this.dvm_features.solar_panel_area
                } => ${this.deviation.solar_panel_area}%`
                remarks += '\n'
            }

            remarks += `${this.$t('dvm_features.f_building_listed')}: ${
                this.dvm_features.f_building_listed
                    ? this.$t('value.yes')
                    : this.$t('value.no')
            } => ${this.deviation.f_building_listed}%`
            remarks += '\n'

            if (this.dvm_features.garden_orientation) {
                remarks += `${this.$t('dvm_features.garden_orientation')}: ${this.$t(
                    'value.' + this.dvm_features.garden_orientation
                )} => ${this.deviation.garden_orientation}%`
                remarks += '\n'
            }

            if (this.dvm_features.outsidePool) {
                remarks += `${this.$t('dvm_features.f_swimming_pool')}: ${
                    this.dvm_features.outsidePool
                        ? this.$t('value.yes')
                        : this.$t('value.no')
                } (${this.dvm_features.pool_size}sqm) => ${this.deviation.outsidePool}%`
                remarks += '\n'
            }

            if (this.dvm_features.surroundings) {
                remarks += `${this.$t('dvm_features.surroundings')}: ${this.$t(
                    'value.' + this.dvm_features.surroundings
                )} => ${this.deviation.surroundings}%`
                remarks += '\n'
            }

            if (this.dvm_features.direct_traffic_level) {
                remarks += `${this.$t('dvm_features.direct_traffic_level')}: ${this.$t(
                    'value.' + this.dvm_features.direct_traffic_level
                )} => ${this.deviation.direct_traffic_level}%`
                remarks += '\n'
            }

            if (this.dvm_features.indirect_traffic_level) {
                remarks += `${this.$t('dvm_features.indirect_traffic_level')}: ${this.$t(
                    'value.' + this.dvm_features.indirect_traffic_level
                )} => ${this.deviation.indirect_traffic_level}%`
                remarks += '\n'
            }

            let positives = ''
            for (const [key, value] of Object.entries(this.dvm_features.other_positive)) {
                if (key != 'otherDetails') {
                    if (value && key != 'other')
                        positives += `${this.$t('value.' + value)}, `
                    if (key == 'other' && value)
                        positives += `${this.dvm_features.other_positive.otherDetails}`
                }
            }

            if (!utils.isEmptyStr(positives)) {
                remarks += `${this.$t('dvm_features.other_positive')}: ${positives} => ${
                    this.deviation.other_positive
                }%`
                remarks += '\n'
            }

            let negatives = ''
            for (const [key, value] of Object.entries(this.dvm_features.other_negative)) {
                if (key != 'otherDetails') {
                    if (value && key != 'other')
                        negatives += `${this.$t('value.' + value)}, `
                    if (key == 'other' && value)
                        negatives += `${this.dvm_features.other_negative.otherDetails}`
                }
            }

            if (!utils.isEmptyStr(negatives)) {
                remarks += `${this.$t('dvm_features.other_negative')}: ${negatives} => ${
                    this.deviation.other_negative
                }%`
                remarks += '\n'
            }

            remarks += '\n'
            remarks += `=> ${this.$t('total_deviation')}: ${this.totalDeviation}%`
            remarks += '\n'
            remarks += `=> ${this.$t('avm_features_deviation')}: ${this.avmDeviation}%`
            remarks += '\n'
            remarks += `=> ${this.$t('market_correction')}: ${
                this.deviation_override.marketCorrection
            }%`
            if (
                this.deviation_override.deviationValue !=
                +this.totalDeviation +
                    +this.deviation_override.marketCorrection +
                    +this.avmDeviation
            ) {
                remarks += '\n'
                remarks += `   => ${this.$t('overridden_deviation')}: ${
                    this.deviation_override.deviationValue
                }%`
            }

            this.updateRemarks(remarks)
        },
    },
}
</script>
<style lang="scss">
.is-grid {
    display: grid;
    grid-template-columns: 300px auto 75px;
    grid-template-rows: repeat(12, auto);
    gap: 2rem 0.5rem;
    align-items: center;

    .is-full-grid-width {
        grid-column: 1 / span 3;
    }

    .update-remarks-button {
        justify-self: end;
    }

    .button-group {
        flex-wrap: wrap;

        & > * {
            .button {
                border-radius: 0 !important;
                border-left-width: 0px;
            }

            &:first-child {
                .button {
                    border-radius: 2px 0 0 2px !important;
                    border-left-width: 1px;
                }
            }

            &:last-child {
                .button {
                    border-radius: 0 2px 2px 0 !important;
                }
            }
        }
    }
}
</style>
