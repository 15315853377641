import Vue from 'vue'
import Router from 'vue-router'
import store from './store/index.js'
import Valuation from '@/components/Valuation.vue'
import Avm from '@/components/avm_demo.vue'
import Dvm from '@/components/dvm'
import Dashboard from '@/components/Dashboard.vue'
import Login from '@/shared/components/Login.vue'
import SSOLogin from '@/shared/components/SSOLogin.vue'
import Report from '@/components/report/Report.vue'
import Support from '@/components/support/FAQ.vue'
import i18n from '@/i18n'
import Admin from '@/shared/components/Admin.vue'

import utils from '@/shared/plugins/utils'

Vue.use(Router)

// get lang from route
let lang = localStorage.getItem('lang') || i18n.locale

const beforeEnter = async (to, from, next) => {
    const actionList = [
        'start',
        'enter',
        'building-type',
        'address',
        'confirm-main-building',
        'select-main-building',
        'select-secondary-buildings',
        'select-parcels',
        'view-building-parcels',
        'extra-info',
        'feedback',
        'faq',
    ]
    const langs = ['nl-BE', 'fr-BE', 'en-BE']
    // if correct lang update localStorage and update lang variable
    if (langs.includes(to.params.lang)) {
        localStorage.setItem('lang', to.params.lang)
        i18n.locale = to.params.lang
    }

    const action_exists = actionList.includes(to.params.action)
    const check_login =
        process.env.VUE_APP_CHECK_LOGIN.toLowerCase() === 'true' && to.name !== 'report'
    if (check_login && store.state.auth.authenticated == null) {
        await store.dispatch('auth/check_authentication')
        if (store.state.auth.authenticated) {
            store.dispatch('auth/start_interval')
        }
    }
    if (check_login && to.name === 'login' && store.state.auth.authenticated) {
        next({ name: 'request', params: { lang: to.params.lang, action: 'start' } })
    } else if (check_login && !store.state.auth.authenticated) {
        let org_hint = utils.getCookie('re-org-hint')
        let redirect_query_param = to.query.r ? `&r=${to.query.r}` : ''
        if (to.name === 'login' && to.query.msg) {
            next()
        } else if (
            process.env.VUE_APP_ROCKESTATE_SSO_ENABLED.toLowerCase() === 'true' &&
            process.env.VUE_APP_ROCKESTATE_SSO_URL &&
            org_hint === 'rockestate'
        ) {
            window.location.href =
                process.env.VUE_APP_ROCKESTATE_SSO_URL + redirect_query_param
        } else if (
            process.env.VUE_APP_CUSTOMER_SSO_ENABLED.toLowerCase() === 'true' &&
            process.env.VUE_APP_CUSTOMER_SSO_URL
        ) {
            window.location.href =
                process.env.VUE_APP_CUSTOMER_SSO_URL + redirect_query_param
        } else if (process.env.VUE_APP_UNAUTHORIZED_URL) {
            window.location.href = process.env.VUE_APP_UNAUTHORIZED_URL
        } else if (to.name !== 'login') {
            next({ name: 'login', params: { lang: to.params.lang } })
        } else {
            next()
        }
    } else if (to.name === 'action' && !action_exists) {
        next({ name: 'request', params: { lang: to.params.lang, action: 'start' } })
    } else if (to.name === 'support' && from.query.valuation_request_ref) {
        to.query.valuation_request_ref = from.query.valuation_request_ref
        next()
    } else if (
        to.name === 'dvm' &&
        (process.env.VUE_APP_ENABLE_DVM.toLowerCase() !== 'true' ||
            (check_login && !store.state.auth.roles.includes('valuer')))
    ) {
        next({ name: 'dashboard', params: { lang: to.params.lang } })
    } else if (
        to.name === 'admin' &&
        check_login &&
        !store.state.auth.roles.includes('admin')
    ) {
        next({ name: 'dashboard', params: { lang: to.params.lang } })
    } else {
        if (typeof to.query.valuation_request_ref !== 'undefined') {
            store.commit('SET_VALUATION_REQUEST_REF', to.query.valuation_request_ref)
            await store.dispatch('getStore')
        }
        next()
    }
}

const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_FRONTEND_URL,
    routes: [
        {
            path: '/',
            redirect: `/${lang}/request/start`,
        },
        { path: '/default', redirect: `/${lang}/request/start` },
        {
            path: '/default/request/:action',
            redirect: `/${lang}/request/:action`,
        },
        {
            path: '/default/login',
            redirect: `/${lang}/login`,
        },
        {
            path: '/default/report/:action',
            redirect: `/${lang}/report/:action`,
        },
        {
            path: '/be-EN/:route',
            redirect: '/en-BE/:route',
        },
        {
            path: '/be-NL/:route',
            redirect: '/nl-BE/:route',
        },
        {
            path: '/be-FR/:route',
            redirect: '/fr-BE/:route',
        },
        {
            path: '/:lang',
            redirect: '/:lang/request/start',
        },
        {
            name: 'request',
            component: Valuation,
            path: '/:lang/request/:action',
            beforeEnter: beforeEnter,
        },
        {
            name: 'dvm',
            component: Dvm,
            path: '/:lang/dvm',
            beforeEnter: beforeEnter,
        },
        {
            name: 'avm',
            component: Avm,
            path: '/:lang/report/:step',
            beforeEnter: beforeEnter,
        },
        {
            name: 'report',
            component: Report,
            path: '/:lang/report',
            beforeEnter: beforeEnter,
        },
        {
            name: 'login',
            component:
                process.env.VUE_APP_CUSTOMER_SSO_ENABLED.toLowerCase() == 'true'
                    ? SSOLogin
                    : Login,
            path: '/:lang/login',
            beforeEnter: beforeEnter,
        },
        {
            name: 'dashboard',
            component: Dashboard,
            path: '/:lang/dashboard',
            beforeEnter: beforeEnter,
        },
        {
            name: 'support',
            component: Support,
            path: '/:lang/support',
            beforeEnter: beforeEnter,
        },
        {
            path: '/:lang/admin',
            name: 'admin',
            component: Admin,
            beforeEnter: beforeEnter,
        },
        {
            // Legacy routing redirect
            path: '/:lang/:action',
            redirect: `/:lang/request/:action`,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.name === 'report') {
            return
        } else {
            return { x: 0, y: 0 }
        }
    },
})

export default router

// what if switch to this type of structure in routes?
// if so it'll be possible to put <router-view/> into Valuation.vue and let Vue do do rest not importing all pages
// https://github.com/Rome00/kockta-mitarbi-master/blob/master/src/router/index.js
