<i18n>
{
    "en-BE":{
        "tab_title": "Input Form",
        "ing": {
            "tab_title": "ING Input Form"
        }
    },
    "fr-BE":{
        "tab_title": "Formulaire",
        "ing": {
            "tab_title": "Formulaire ING"
        }
    },
    "nl-BE":{
        "tab_title": "Input Form",
        "ing": {
            "tab_title": "ING Input Form"
        }
    }
}
</i18n>

<template>
    <div id="valuation">
        <div class="columns is-centered">
            <div
                class="column pb-0 pr-0"
                :class="[
                    $route.params.action == 'faq'
                        ? 'is-3 is-2-desktop '
                        : 'is-4 is-3-desktop ',
                ]"
            >
                <app-side-steps />
            </div>
            <div
                class="column pl-0"
                :class="[
                    $route.params.action == 'faq'
                        ? 'is-10-desktop is-9'
                        : 'is-9-desktop is-8',
                    { skiline: $route.params.action === 'start' },
                ]"
            >
                <app-start v-if="$route.params.action == 'start'" />
                <app-enter
                    v-if="$route.params.action == 'enter' && flow_type !== 'avm_demo'"
                />
                <app-building-type v-if="$route.params.action == 'building-type'" />
                <app-address v-if="$route.params.action == 'address'" />
                <app-map-select
                    v-if="
                        [
                            'confirm-main-building',
                            'select-main-building',
                            'select-secondary-buildings',
                            'select-parcels',
                            'view-building-parcels',
                        ].includes($route.params.action)
                    "
                />
                <app-extra-info
                    v-if="$route.params.action == 'extra-info'"
                    :is-exiting="isExitingExtraInfo"
                    :proceed-fn="exitExtraInfoFn"
                    :cancel-fn="cancelNavFn"
                />
                <app-feed-back v-if="$route.params.action == 'feedback'" />
                <app-info v-if="getRouteForInfo" />

                <app-faq v-if="$route.params.action == 'faq'" />
            </div>
        </div>
    </div>
</template>

<script>
import AppStart from './request/01-Start.vue'
import AppEnter from './request/02-Enter.vue'
import AppBuildingType from './request/03-BuildingType.vue'
import AppAddress from './request/04-address'
import AppMapSelect from './request/04.1-MapSelect'
import AppExtraInfo from './request/05.0-ExtraInfo.vue'
import AppFeedBack from './request/06-FeedBack'
import AppSideSteps from './request/SideSteps'
import AppInfo from '@/shared/components/Info.vue'
import AppFaq from '@/shared/components/FAQ.vue'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { localeMixin } from '@/components/mixins/locale.js'

export default {
    name: 'Valuation',
    components: {
        AppStart,
        AppEnter,
        AppAddress,
        AppMapSelect,
        AppSideSteps,
        AppExtraInfo,
        AppFeedBack,
        AppBuildingType,
        AppInfo,
        AppFaq,
    },
    mixins: [localeMixin],
    beforeRouteEnter(to, from, next) {
        if (to.params.action === 'start') {
            next((vm) => {
                vm.RESET_FULL_DATA()
                vm.RESET_VAL()
                vm.SET_CUSTOMER_REF(null)
            })
        } else {
            next()
        }
    },
    beforeRouteLeave(to, from, next) {
        if (from.params.action === 'extra-info') {
            this.isExitingExtraInfo = true
            this.exitExtraInfoFn = () => {
                this.onRouteUpdate(to, from)
                next()
            }
            this.cancelNavFn = () => {
                this.isExitingExtraInfo = false
                this.exitExtraInfoFn = null
                this.cancelNavFn = null
                next(false)
            }
        } else {
            this.isExitingExtraInfo = false
            this.exitExtraInfoFn = null
            this.cancelNavFn = null
            this.onRouteUpdate(to, from)
            next()
        }
    },
    data: () => ({
        isExitingExtraInfo: false,
        exitExtraInfoFn: null,
        cancelNavFn: null,
    }),
    computed: {
        ...mapGetters('Address', ['getFullBuildingData']),
        ...mapGetters(['getFullStore', 'flow_type']),
        getRouteForInfo() {
            return (
                this.$route.params.action !== 'faq' &&
                process.env.VUE_APP_LOCALE_OVERWRITE === 'ing'
            )
        },
        tabTitle() {
            return this.t('tab_title')
        },
    },
    watch: {
        // updete BE store with local data and then get updated data from BE on route change
        $route(to, from) {
            this.onRouteUpdate(to, from)
        },
    },
    created() {
        this.$i18n.locale = this.$route.params.lang
        // get store after reload
        if (this.$route.params.action !== 'start') this.getStore()
    },
    methods: {
        ...mapActions('Address', ['fetch_features']),
        ...mapActions(['updateStore', 'getStore']),
        ...mapMutations(['UPDATE_STATUS', 'RESET_VAL', 'SET_CUSTOMER_REF']),
        ...mapMutations('Address', ['RESET_FULL_DATA']),
        onRouteUpdate(to, from) {
            if (to.params.action === 'start') {
                this.RESET_FULL_DATA()
                this.RESET_VAL()
                this.SET_CUSTOMER_REF(null)
            } else if (to.params.action === 'enter') {
            } else if (
                [
                    'enter',
                    'building-type',
                    'address',
                    'confirm-main-building',
                    'select-main-building',
                    'select-secondary-buildings',
                    'select-parcels',
                    'view-building-parcels',
                ].includes(from.params.action) &&
                to.params.action !== 'extra-info'
            ) {
                if (this.$store.state.status === 'predraft') {
                    this.updateStore({
                        data: this.getFullBuildingData,
                        lang: this.$i18n.locale,
                        action: 'save-predraft',
                    })
                }
            } else if (to.params.action === 'extra-info') {
                if (
                    this.$store.state.status === 'predraft' ||
                    this.$store.state.status === 'draft'
                ) {
                    this.updateStore({
                        data: this.getFullBuildingData,
                        lang: this.$i18n.locale,
                        action: 'save-draft',
                    })
                }
            }
        },
    },
    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: this.tabTitle,
            // all titles will be injected into this template
            titleTemplate: '%s | Rock.estate Valuation App',
        }
    },
}
</script>

<style src="@/assets/style/index.scss" lang="scss"></style>

<style lang="scss" scoped>
.skiline {
    background: url('./../assets/icons/img-skyline.svg') no-repeat bottom right;
    background-size: contain;
}
</style>
