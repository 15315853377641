<template>
    <div :class="'ribbon ' + type">
        <slot></slot>
    </div>
</template>
<script>

export default {
    name: 'Ribbon',
    props: {
        type: {
            type: String,
            validator: (prop) => [
                'is-primary',
                'is-warning',
                'is-default',
            ].includes(prop),
            default: 'is-primary',
        }
    }
}
</script>
<style lang="scss" scoped>
    @import '../assets/style/buefy.scss';
    
    .ribbon {
        position: fixed;
        bottom: 0;
        right: 1rem;
        left: 1rem;
        padding: 1rem;
        text-align: center;
        border-radius: 5px 5px 0 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        button {
            margin: 0 1rem;
        }

        &.is-primary {
            background: $primary;
            color: white;
        }

        &.is-warning {
            background: $warning;
            color: rgba(0, 0, 0, 0.7);
        }

        &.is-danger {
            background: $danger;
            color: white;
        }
    }
</style>