<template>
    <div class="columns is-multiline has-text-left">
        <div class="column is-6 has-text-left pb-0">
            <img
                width="150px"
                src="@/shared/assets/icons/rockestate-logo-horizontal-dark.svg"
            />
        </div>
        <div class="column is-6 has-text-right is-size-6 pb-0">
            <div class="has-text-weight-bold has-text-black">
                {{ $t('front.title') }}
            </div>
        </div>
        <div class="column is-3 py-2">
            <p class="is-size-7 mb-0">{{ $t('address_text.full_address') }}</p>
            <p class="has-text-weight-bold has-text-black is-size-7">
                {{ getFullStore.Address.data.address_text.full_address }}
            </p>
        </div>
        <div class="column is-3 py-2">
            <p class="is-size-7 mb-0">{{ $t('valuation.date') }}</p>
            <p class="has-text-weight-bold has-text-black is-size-7">
                {{ formatted_date }}
            </p>
        </div>
        <div class="column is-3 py-2">
            <p class="is-size-7 mb-0">{{ $t('valuation.type') }}</p>
            <p class="has-text-weight-bold has-text-black is-size-7">
                {{ $t(`value.${getValuation.type}`) }}
            </p>
        </div>
        <div class="column is-3 py-2">
            <p class="is-size-7 mb-0">{{ $t('request.valuation_request_ref') }}</p>
            <p class="has-text-weight-bold has-text-black is-size-7">
                {{ getFullStore.valuation_request_ref }}
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

const date_formatter = new Intl.DateTimeFormat('fr-BE', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    /*second: 'numeric',*/
    timeZone: 'Europe/Brussels',
})

export default {
    computed: {
        ...mapGetters(['getFullStore', 'getValuation']),
        formatted_date() {
            return date_formatter.format(this.getValuation.date)
        },
    },
}
</script>

<style scoped></style>
