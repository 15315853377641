/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'insulation': {
    width: 33,
    height: 30,
    viewBox: '0 0 33 30',
    data: '<path class="st0" d="M32.3,21.2l-3.6-1.6l3.5-1.6c0.3-0.1,0.5-0.4,0.5-0.7v-4.6c0-0.3-0.2-0.6-0.5-0.8c0,0-3.5-1.6-3.5-1.6l3.5-1.6 c0.4-0.2,0.6-0.7,0.4-1.1c-0.1-0.2-0.2-0.3-0.4-0.4L16.8,0.3c-0.2-0.1-0.5-0.1-0.7,0L0.7,7.3C0.1,7.5,0,8.5,0.7,8.8 c0,0,3.5,1.6,3.5,1.6l-3.5,1.6c-0.3,0.1-0.5,0.4-0.5,0.7c0,0,0,4.6,0,4.6c0,0.3,0.2,0.6,0.5,0.7l3.5,1.6l-3.6,1.6 c-0.4,0.2-0.6,0.7-0.4,1.1c0.1,0.2,0.2,0.3,0.4,0.4l15.5,6.8c0.2,0.1,0.5,0.1,0.7,0l15.5-6.8C33,22.4,33,21.4,32.3,21.2z M5.3,18.3 l-3.5-1.6v-2.9l3.5,1.6V18.3z M10.2,20.5L6.9,19v-2.9l3.2,1.4V20.5z M15.7,22.9l-3.9-1.7v-2.9l3.9,1.7V22.9z M16.5,18.6L3,12.7 l3.2-1.4l10,4.4c0.2,0.1,0.5,0.1,0.7,0l10-4.4l3.2,1.4L16.5,18.6z M21.2,21.2l-3.9,1.7V20l3.9-1.7V21.2z M26.8,18.7l-0.2,0.1l0,0 l-3.7,1.6v-2.9l3.9-1.7V18.7z M28.4,15.1l2.8-1.3v2.9L28.4,18V15.1z"/>'
  }
})
