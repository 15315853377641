<template>
    <div :class="`floor-diagram-container py-6 px-0 ${showDiagram ? 'shown' : 'hidden'}`">
        <div class="is-flex is-align-items-center is-justify-content-center mb-5 ml-6">
            <b-button type="is-primary" :disabled="topFloor <= 0" @click="reduceTopFloor">
                -
            </b-button>
            <span class="floor-diagram-number is-size-4">
                {{ topFloor }}
            </span>
            <b-button
                type="is-primary"
                :disabled="topFloor >= 40"
                @click="increaseTopFloor"
            >
                +
            </b-button>
        </div>
        <div
            class="is-flex is-flex-direction-column is-align-items-center floor-diagram-building ml-2"
        >
            <div
                class="top-floor-border is-flex is-align-items-center is-justify-content-center is-flex-shrink-0 is-flex-grow-0 floor-diagram-floor"
            >
                <span />
                <div class="top-border" />
            </div>
            <div
                v-for="floor in buildingFloors"
                :key="'floor_diagram_' + floor"
                :class="
                    `is-flex is-align-items-center is-justify-content-center is-flex-shrink-0 is-flex-grow-0 floor-diagram-floor mr-4 ${floor ==
                        0 && 'ground-floor'} ${floor == -1 && 'first-negative'}`
                "
            >
                <img v-if="floor == 0" src="@/assets/img/tree.svg" class="tree-pic" />
                <span
                    :class="
                        `is-size-5 ${floor.toString().includes('x') && 'hidden-number'}`
                    "
                    @click="toggleFloor(floor)"
                >
                    {{ floor.toString().includes('x') ? floor[0] : floor }}
                </span>
                <div
                    v-if="!floor.toString().includes('x')"
                    :class="
                        `floor-pic ml-2 px-3 ${activatedFloors.includes(floor) &&
                            (isError ? 'error' : 'selected')} ${
                            floor >= 0 ? 'has-windows' : 'has-small-windows'
                        }`
                    "
                    @click="toggleFloor(floor)"
                >
                    <div class="window" />
                    <div v-if="floor > 0" class="window" />
                    <div v-if="floor == 0" class="door" />
                    <div class="window" />
                </div>
                <div
                    v-else
                    :class="
                        `floor-pic mid-floor ml-2 px-3 ${floor === 'xx' &&
                            'top-floor'} ${activatedFloors.includes(+floor[0]) &&
                            (isError ? 'error' : 'selected')}`
                    "
                />
                <img v-if="floor == 0" src="@/assets/img/tree.svg" class="tree-pic" />
            </div>
        </div>
        <div class="is-flex is-align-items-center is-justify-content-center mt-5 ml-6">
            <b-button
                type="is-primary"
                :disabled="bottomFloor <= -5"
                @click="reduceBottomFloor"
            >
                -
            </b-button>
            <span class="floor-diagram-number is-size-4">
                {{ bottomFloor }}
            </span>
            <b-button
                type="is-primary"
                :disabled="bottomFloor >= 0"
                @click="increaseBottomFloor"
            >
                +
            </b-button>
        </div>
        <div v-if="isError" class="error-message p-2 has-text-centered">
            <p>{{ errorMessage }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        bottomFloor: {
            type: Number,
            required: true,
        },
        topFloor: {
            type: Number,
            required: true,
        },
        increaseBottomFloor: {
            type: Function,
            required: true,
        },
        reduceBottomFloor: {
            type: Function,
            required: true,
        },
        increaseTopFloor: {
            type: Function,
            required: true,
        },
        reduceTopFloor: {
            type: Function,
            required: true,
        },
        toggleFloor: {
            type: Function,
            required: true,
        },
        activatedFloors: {
            type: Array,
            required: true,
        },
        buildingFloors: {
            type: Array,
            required: true,
        },
        isError: {
            type: Boolean,
            required: true,
        },
        showDiagram: {
            type: Boolean,
            required: true,
        },
        errorMessage: {
            type: String,
            required: true,
        },
    },
}
</script>
<style lang="scss" scoped>
.floor-diagram-container {
    background: white;
    width: 400px;
    position: absolute;
    top: 15vh;
    left: -22vw;
    transition: opacity 0.2s ease-in-out;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 24, 55, 0.2);

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    &.shown {
        opacity: 1;
        pointer-events: all;
    }

    .error-message {
        position: absolute;
        left: 10%;
        right: 10%;
        width: 80%;
        top: 40%;
        background-color: #001837cc;
        color: white;
        border-radius: 5px;
    }

    .floor-diagram-number {
        text-align: center;
        width: 3rem;
    }

    .floor-diagram-building {
        width: 100%;

        .top-floor-border {
            .top-border {
                width: 161px;
                margin-right: 8px;
                height: 2px;
                background-color: #798da6;
                border-radius: 2px;
            }
        }

        .floor-diagram-floor {
            width: 100%;
            box-sizing: border-box;

            .tree-pic {
                position: absolute;
                bottom: 0;
                left: 35px;

                &:last-child {
                    left: auto;
                    right: 30px;
                }
            }

            &.ground-floor {
                border-bottom: 2px solid #798da6;
                position: relative;

                .floor-pic {
                    border-bottom: 0;
                }
            }

            & > span {
                width: 2rem;
                cursor: pointer;

                &.hidden-number {
                    opacity: 0;
                    font-size: 0.1rem !important;
                }
            }

            .floor-pic {
                width: 150px;
                height: 60px;
                border: 2px solid #798da6;
                background-color: #798da626;

                &:hover:not(.mid-floor) {
                    cursor: pointer;
                    background-color: #0096ff66;
                }

                &.mid-floor {
                    height: 20px;
                    border-top: 0;
                }

                &.top-floor {
                    border-top: none;
                }

                &.has-windows {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .window {
                        height: 48%;
                        width: 23%;
                        background: white;
                        border-radius: 3px;
                        border: 2px solid #798da6;
                        position: relative;
                        bottom: 4%;
                    }

                    .door {
                        height: 80%;
                        width: 23%;
                        position: relative;
                        top: 10%;
                        background: white;
                        border-radius: 3px 3px 0 0;
                        border: 2px solid #798da6;
                        border-bottom: 0;
                    }
                }

                &.has-small-windows {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .window {
                        height: 20px;
                        width: 20px;
                        background: white;
                        border-radius: 3px;
                        border: 2px solid #798da6;
                        position: relative;
                        bottom: 5px;
                    }
                }

                &.error {
                    border-color: #af5564;
                    background-color: #ff000066;

                    .window,
                    .door {
                        border-color: #af5564;
                    }
                }

                &.selected {
                    border-color: #4991ca;
                    background-color: #0096ff66;

                    .window,
                    .door {
                        border-color: #4991ca;
                    }
                }
            }

            &.first-negative {
                .floor-pic {
                    border-top: 0;
                }
            }

            &:not(:last-child) {
                .floor-pic {
                    border-bottom: 0;
                }
            }
        }
    }
}
</style>
