<i18n>
{
  "nl-BE": {
    "house": "Een huis",
    "house_comment": "Gebouw met één enkele woonentiteit",
    "apartment": "Een appartement",
    "apartment_comment": "Een woonentiteit die deel uitmaakt van een groter gebouw",
    "previous": "Vorige",
    "next": "Volgende"
  },
  "en-BE": {
    "house": "A house",
    "house_comment": "Building with one single living entity",
    "apartment": "An apartment",
    "apartment_comment": "A living entity part of a larger building",
    "previous": "Previous",
    "next": "Next"
  },
  "fr-BE": {
    "house": "Une maison",
    "house_comment": "Bâtiment comptant une seule entité",
    "apartment": "Un appartement",
    "apartment_comment": "Une entité qui fait partie d'un bâtiment plus large",
    "previous": "Précédent",
    "next": "Suivant"
  }
}
</i18n>

<template>
    <div
        class="is-flex"
        :class="[
            $vssWidth > 1123
                ? 'is-justify-content-flex-start'
                : 'is-flex-direction-column is-justify-content-center',
        ]"
    >
        <transition name="fade-opacity" :duration="{ leave: 0 }">
            <div
                ref="box"
                :class="{
                    'choose-type-box': true,
                    'not-choosen': value !== 'house',
                    chosen: value === 'house',
                    disable: !isPredraft,
                }"
                @click="selectType('house')"
                @mouseenter="leftIcon = '#fff'"
                @mouseleave="leaveLeft"
            >
                <div class="type-icon home">
                    <svg-icon
                        v-if="activeIcon === 'house'"
                        icon="home"
                        hight="50"
                        scale="1.4"
                        color="#fff"
                    />
                    <svg-icon
                        v-else
                        icon="home"
                        hight="50"
                        scale="1.4"
                        :color="leftIcon"
                    />
                </div>

                <div class="type-text">
                    <div class="type-text-title has-text-weight-bold">
                        {{ $t('house') }}
                    </div>
                    <div class="type-text-text">
                        {{ $t('house_comment') }}
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade-opacity" :duration="{ leave: 0 }">
            <div
                class="choose-type-box"
                :class="{
                    chosen: value === 'apartment',
                    'not-choosen': value !== 'apartment',
                    'mt-4': $vssWidth < 1123,
                    disable: !isPredraft,
                }"
                @click="selectType('apartment')"
                @mouseenter="rightIcon = '#fff'"
                @mouseleave="leaveRight"
            >
                <div class="type-icon apartment">
                    <svg-icon
                        v-if="activeIcon === 'apartment'"
                        icon="apartment"
                        hight="50"
                        scale="1.4"
                        color="#fff"
                    />
                    <svg-icon
                        v-else
                        icon="apartment"
                        hight="50"
                        scale="1.4"
                        :color="rightIcon"
                    />
                </div>
                <div class="type-text">
                    <div class="type-text-title has-text-weight-bold">
                        {{ $t('apartment') }}
                    </div>
                    <div class="type-text-text">
                        {{ $t('apartment_comment') }}
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'BuildingType',
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            leftIcon: '#798DA6',
            rightIcon: '#798DA6',
            activeIcon: '',
        }
    },
    computed: {
        ...mapGetters(['isPredraft']),
        ...mapGetters('Address', ['getFeatures']),
    },
    mounted() {
        if (this.getFeatures.f_building_type === 'apartment') {
            this.rightIcon = ' #fff'
        } else if (this.getFeatures.f_building_type === 'house') {
            this.leftIcon = ' #fff'
        } else {
            this.rightIcon = ' #798DA6'
            this.leftIcon = '#798DA6'
        }
    },
    methods: {
        selectType(val) {
            this.$emit('update:buildingType', val)
            this.activeIcon = val
        },
        leaveLeft() {
            if (this.getFeatures.f_building_type === 'house') {
                this.leftIcon = '#fff'
            } else {
                this.leftIcon = '#798DA6'
            }
        },
        leaveRight() {
            if (this.getFeatures.f_building_type === 'apartment') {
                this.rightIcon = '#fff'
            } else {
                this.rightIcon = '#798DA6'
            }
        },
        enterLeft() {
            this.leftIcon = '#fff'
        },
        enterRight() {
            this.rightIcon = '#fff'
        },
    },
}
</script>

<style lang="scss">
@import '@/shared/assets/style/buefy.scss';
.choose-type-group {
    width: 100%;
    transition: 0.5s all;
}
.choose-type-box {
    min-width: 360px;
    height: 140px;
    border-radius: 5px;
    box-shadow: 0 2px 14px 0 rgba(0, 47, 80, 0.1);
    border: solid 1px #d6e2e9;
    background-color: #ffffff;
    padding: 30px 20px;
    box-sizing: border-box;
    display: block;
    align-items: center;
    cursor: pointer;
    transition: 0.4s all;
    &:first-child {
        margin-right: 40px;
    }
    &.disable {
        pointer-events: none !important;
    }

    & .type-icon {
        width: 80px;
        height: 80px;
        border: solid 1px rgba(78, 94, 114, 0.2);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s all;
        float: left;
        margin-left: 20px;
        svg {
            transition: 0.3s all;
        }

        &.home {
            background-color: rgba(78, 94, 114, 0.1);
        }
        &.apartment {
            background-color: rgba(78, 94, 114, 0.1);
        }
    }

    .type-text {
        float: left;
        margin-left: 30px;
        .type-text-title {
            font-size: 18px;
            color: #001837;
            margin-bottom: 8px;
        }
        .type-text-text {
            width: 190px;
            font-size: 16px;
            line-height: 1.31;
            color: #798da6;
        }
    }

    &:hover,
    &.chosen {
        //border-color: $blue-primary;
        border: 1px solid #009cff;
        .type-icon {
            &.home {
                background-color: $primary;
            }
            &.apartment {
                background-color: $primary;
            }
        }
        .type-text {
            .type-text-title {
                color: $primary;
            }
        }
    }
    &.not-choosen {
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
    }
}
@media screen and (max-width: 1123px) {
    .choose-type-box {
        width: 80%;
        max-width: 630px;
        display: block;
        .type-icon {
            margin-left: 30px;
            margin-right: 20px;
        }
    }
}
@media screen and (max-width: 1024px) {
    .choose-type-box {
        width: 90%;
        max-width: 630px;
        display: block;
        .type-icon {
            margin-left: 30px;
            margin-right: 20px;
        }
    }
}
</style>
