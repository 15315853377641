/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'house': {
    width: 22,
    height: 18,
    viewBox: '0 0 22 18',
    data: '<path class="st0" d="M21.4,8.7l-2.7-2.2V1.2c0-0.3-0.3-0.6-0.6-0.6h-3.5c-0.3,0-0.6,0.3-0.6,0.6v1.5l-2.7-2.2 c-0.2-0.2-0.5-0.2-0.8,0l-10,8.2C0.4,9,0.3,9.3,0.5,9.6c0.2,0.2,0.6,0.3,0.8,0.1l1.9-1.6v8.5c0,0.3,0.3,0.6,0.6,0.6h14.2 c0.3,0,0.6-0.3,0.6-0.6V8.1l1.9,1.6c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3-0.1,0.5-0.2C21.7,9.3,21.6,9,21.4,8.7z M12.3,16H9.7v-4.4 h2.5V16z"/>'
  }
})
