<i18n>
{
    "en-BE":{
        "analysis": "Analysis",
        "extreme_value": "Extreme value for",
        "no_bedroom": "0 bedrooms!",
        "price": "Price",
        "touching_sides": "Touching sides"

    },
    "fr-BE":{
        "analysis": "Analyse",
        "extreme_value": "Valeur extrême pour",
        "no_bedroom": "0 chambre à coucher!",
        "price": "Prix",
        "touching_sides": "Touching sides"

    },
    "nl-BE":{
        "analysis": "Analysis",
        "extreme_value": "Extreme value for",
        "no_bedroom": "0 bedrooms!",
        "price": "Price",
        "touching_sides": "Touching sides"
    }
}
</i18n>
<template>
    <div class="is-w100">
        <Map
            :center="[features.f_lat, features.f_lng]"
            :region="features.region"
            :population="avmTransaction.reference_listings"
            :assessed="features"
            :price="avmTransaction.estimation.asking_price_q50"
            :prices="prices"
            :price-per-sqm-array="pricePerSqmArray"
            :address="address"
            :update-remarks="updateRemarks"
        />

        <div class="is-flex is-justify-content-center">
            <div id="radar" />
        </div>

        <div
            class="is-flex is-size-4 mt-5 is-flex-wrap-wrap is-justify-content-space-between mb-5 valuations-prices"
        >
            <span>
                {{ $t('features.transaction_value') }}:
                <span class="has-text-primary has-text-weight-bold">
                    {{
                        withSpaces(
                            Math.round(
                                avmTransaction.estimation.asking_price_q50 / 1000
                            ) * 1000
                        )
                    }}
                    €
                </span>
            </span>
            <span>
                {{ $t('valuation.forced_sale_value') }}:
                {{ withSpaces(forcedSaleValue) }} €
            </span>
            <span>
                {{ $t('valuation.rental_value') }}:
                {{
                    withSpaces(
                        Math.round(avmRental.estimation.asking_price_q50 / 10) * 10
                    )
                }}
                €
            </span>
            <span>
                {{ $t('valuation.reconstruction_value') }}:
                {{ withSpaces(reconstructionValue) }} €
            </span>
        </div>
        <h1 class="is-size-4 has-text-black has-text-weight-bold">
            {{ $t('analysis') }}
        </h1>
        <!-- <div>
            <h3
                v-for="warning in warnings"
                :key="`warning_${warning}`"
                class="is-size-5 has-text-danger has-text-weight-bold"
            >
                {{ $t('extreme_value') }} {{ $t(warning) }}!
            </h3>
            <h3 v-if="isApartment && !features.f_bedrooms">{{ $t('no_bedroom') }}</h3>
        </div> -->
        <div class="is-avm-grid">
            <kernel-chart
                :data-array="prices"
                :target-value="targetPrice"
                title="Price"
            />
            <div>
                <h3 class="is-size-5 has-text-weight-bold">{{ $t('price') }}</h3>
                <ul>
                    <li>x={{ withSpaces(targetPrice) }}</li>
                    <li>x̃={{ withSpaces(medianPrice) }}</li>
                    <li>x̄={{ withSpaces(meanPrice) }}</li>
                    <li>s={{ withSpaces(priceDeviance) }}</li>
                    <li>%={{ priceTile }}</li>
                </ul>
            </div>

            <kernel-chart
                :data-array="epcs"
                :target-value="targetEpc"
                :title="$t('features.f_epc')"
            />
            <div>
                <h3
                    class="is-size-5 has-text-weight-bold"
                    :class="{
                        'has-text-danger': warnings.includes('features.f_epc'),
                    }"
                >
                    {{ $t('features.f_epc')
                    }}{{ warnings.includes('features.f_epc') ? ' (EXTREME VALUE)' : '' }}
                </h3>
                <ul>
                    <li>x={{ targetEpc }}</li>
                    <li>x̃={{ medianEpc }}</li>
                    <li>x̄={{ meanEpc }}</li>
                    <li>s={{ epcDeviance }}</li>
                    <li>%={{ epcTile }}</li>
                </ul>
                <div class="is-flex is-align-items-center mt-2">
                    <b-input v-model="deviations.epc" type="number" size="is-small" />
                    <span class="ml-1 ">%</span>
                </div>
            </div>

            <kernel-chart
                :data-array="years"
                :target-value="targetYear"
                :title="$t('features.f_construction_year')"
            />
            <div>
                <h3
                    class="is-size-5 has-text-weight-bold"
                    :class="{
                        'has-text-danger': warnings.includes(
                            'features.f_construction_year'
                        ),
                    }"
                >
                    {{ $t('features.f_construction_year')
                    }}{{
                        warnings.includes('features.f_construction_year')
                            ? ' (EXTREME VALUE)'
                            : ''
                    }}
                </h3>
                <ul>
                    <li>x={{ targetYear }}</li>
                    <li>x̃={{ medianYear }}</li>
                    <li>x̄={{ meanYear }}</li>
                    <li>s={{ yearDeviance }}</li>
                    <li>%={{ yearTile }}</li>
                </ul>
                <div class="is-flex is-align-items-center mt-2">
                    <b-input v-model="deviations.year" type="number" size="is-small" />
                    <span class="ml-1">%</span>
                </div>
            </div>

            <kernel-chart
                :data-array="livingAreas"
                :target-value="targetLivingArea"
                :title="$t('features.f_living_area')"
            />
            <div>
                <h3
                    class="is-size-5 has-text-weight-bold"
                    :class="{
                        'has-text-danger': warnings.includes('features.f_living_area'),
                    }"
                >
                    {{ $t('features.f_living_area')
                    }}{{
                        warnings.includes('features.f_living_area')
                            ? ' (EXTREME VALUE)'
                            : ''
                    }}
                </h3>
                <ul>
                    <li>x={{ targetLivingArea }}</li>
                    <li>x̃={{ medianLiving }}</li>
                    <li>x̄={{ meanLiving }}</li>
                    <li>s={{ livingDeviance }}</li>
                    <li>%={{ livingTile }}</li>
                </ul>
                <div class="is-flex is-align-items-center mt-2">
                    <b-input v-model="deviations.living" type="number" size="is-small" />
                    <span class="ml-1">%</span>
                </div>
            </div>

            <kernel-chart
                v-if="!isApartment"
                :data-array="parcelAreas"
                :target-value="targetParcelArea"
                :title="$t('features.f_parcel_area')"
            />
            <div v-if="!isApartment" class="flex-grow-1 flex-shrink-0">
                <h3
                    class="is-size-5 has-text-weight-bold"
                    :class="{
                        'has-text-danger': warnings.includes('features.f_parcel_area'),
                    }"
                >
                    {{ $t('features.f_parcel_area')
                    }}{{
                        warnings.includes('features.f_parcel_area')
                            ? ' (EXTREME VALUE)'
                            : ''
                    }}
                </h3>
                <ul>
                    <li>x={{ targetParcelArea }}</li>
                    <li>x̃={{ medianParcel }}</li>
                    <li>x̄={{ meanParcel }}</li>
                    <li>s={{ parcelDeviance }}</li>
                    <li>%={{ parcelTile }}</li>
                </ul>
                <div v-if="!isApartment" class="is-flex is-align-items-center mt-2">
                    <b-input v-model="deviations.parcel" type="number" size="is-small" />
                    <span class="ml-1">%</span>
                </div>
            </div>

            <kernel-chart
                v-if="!isApartment"
                :data-array="buildingAreas"
                :target-value="targetBuildingArea"
                :title="$t('features.f_building_area')"
            />
            <div v-if="!isApartment" class="flex-grow-1 flex-shrink-0">
                <h3
                    class="is-size-5 has-text-weight-bold"
                    :class="{
                        'has-text-danger': warnings.includes('features.f_building_area'),
                    }"
                >
                    {{ $t('features.f_building_area')
                    }}{{
                        warnings.includes('features.f_building_area')
                            ? ' (EXTREME VALUE)'
                            : ''
                    }}
                </h3>
                <ul>
                    <li>x={{ targetBuildingArea }}</li>
                    <li>x̃={{ medianBuilding }}</li>
                    <li>x̄={{ meanBuilding }}</li>
                    <li>s={{ buildingDeviance }}</li>
                    <li>%={{ buildingTile }}</li>
                </ul>
                <div v-if="!isApartment" class="is-flex is-align-items-center mt-2">
                    <b-input
                        v-model="deviations.building"
                        type="number"
                        size="is-small"
                    />
                    <span class="ml-1">%</span>
                </div>
            </div>

            <kernel-chart
                v-if="!isApartment"
                :data-array="gardenAreas"
                :target-value="targetGardenArea"
                :title="$t('features.f_garden_area')"
            />
            <div v-if="!isApartment" class="flex-grow-1 flex-shrink-0">
                <h3
                    class="is-size-5 has-text-weight-bold"
                    :class="{
                        'has-text-danger': warnings.includes('features.f_garden_area'),
                    }"
                >
                    {{ $t('features.f_garden_area')
                    }}{{
                        warnings.includes('features.f_garden_area')
                            ? ' (EXTREME VALUE)'
                            : ''
                    }}
                </h3>
                <ul>
                    <li>x={{ targetGardenArea }}</li>
                    <li>x̃={{ medianGarden }}</li>
                    <li>x̄={{ meanGarden }}</li>
                    <li>s={{ gardenDeviance }}</li>
                    <li>%={{ gardenTile }}</li>
                </ul>
                <div v-if="!isApartment" class="is-flex is-align-items-center mt-2">
                    <b-input v-model="deviations.garden" type="number" size="is-small" />
                    <span class="ml-1">%</span>
                </div>
            </div>

            <kernel-chart
                v-if="!isApartment"
                :data-array="touchingSides"
                :target-value="targetTouchingSides"
                :is-sides="true"
                :title="$t('touching_sides')"
            />
            <div v-if="!isApartment" class="flex-grow-1 flex-shrink-0">
                <h3
                    class="is-size-5 has-text-weight-bold"
                    :class="{
                        'has-text-danger': warnings.includes('touching_sides'),
                    }"
                >
                    {{ $t('touching_sides')
                    }}{{ warnings.includes('touching_sides') ? ' (EXTREME VALUE)' : '' }}
                </h3>
                <ul>
                    <li>x={{ targetTouchingSides }}</li>
                    <li>x̃={{ medianSides }}</li>
                    <li>x̄={{ meanSides }}</li>
                    <li>s={{ sidesDeviance }}</li>
                    <li>%={{ sidesTile }}</li>
                </ul>
                <div v-if="!isApartment" class="is-flex is-align-items-center mt-2">
                    <b-input v-model="deviations.sides" type="number" size="is-small" />
                    <span class="ml-1">%</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as d3 from 'd3'
import KernelChart from './KernelChart.vue'
import Map from './ReferencesMap.vue'
import radarchart from './radarchart.js'
import utils from '@/shared/plugins/utils'

export default {
    name: 'AVM',
    components: {
        KernelChart,
        Map,
    },
    props: {
        address: {
            type: Object,
            required: true,
        },
        avmRental: {
            type: Object,
            required: true,
        },
        avmTransaction: {
            type: Object,
            required: true,
        },
        features: {
            type: Object,
            required: true,
        },
        reconstructionValue: {
            type: Number,
            required: true,
        },
        forcedSaleValue: {
            type: Number,
            required: true,
        },
        updateDeviation: {
            type: Function,
            required: true,
        },
        updateRemarks: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            spider_chart_options: {
                responsive: true,
                maintainAspectRatio: false,
                height: 800,
                width: 800,
                tension: 10,
                scales: {
                    r: {
                        angleLines: {
                            display: false,
                        },
                    },
                    suggestedMax: 100,
                },
            },
            radarChartOptions: {
                w: 400,
                h: 400,
                maxValue: 100,
                levels: 4,
                roundStrokes: false,
                margin: { top: 100, right: 100, bottom: 100, left: 100 }, //The margins of the SVG
                labelFactor: 1.15, //How much farther than the radius of the outer circle should the labels be placed
            },
            deviations: {
                epc: 0,
                year: 0,
                living: 0,
                building: 0,
                parcel: 0,
                garden: 0,
                sides: 0,
            },
        }
    },
    computed: {
        isApartment() {
            return this.features?.f_building_type === 'apartment'
        },
        prices() {
            const array = this.avmTransaction.reference_listings.map(({ prijs }) => prijs)
            array.sort((a, b) => a - b)
            return array
        },
        targetPrice() {
            return (
                Math.round(this.avmTransaction.estimation.asking_price_q50 / 1000) * 1000
            )
        },
        medianPrice() {
            return Math.round(d3.quantileSorted(this.prices, 0.5) / 1000) * 1000
        },
        meanPrice() {
            return Math.round(d3.mean(this.prices) / 1000) * 1000
        },
        priceDeviance() {
            return Math.round(d3.deviation(this.prices) / 1000) * 1000
        },
        priceTile() {
            return (d3.bisect(this.prices, this.targetPrice) / this.prices.length) * 100
        },
        pricePerSqmArray() {
            const array = this.avmTransaction.reference_listings.map(
                ({ prijs, f_living_area }) => Math.round(prijs / f_living_area)
            )
            array.sort((a, b) => a - b)
            return array
        },
        epcs() {
            const array = this.avmTransaction.reference_listings.map(({ f_epc }) => f_epc)
            array.sort((a, b) => a - b)
            return array
        },
        targetEpc() {
            return this.avmTransaction.input_data.f_epc
        },
        medianEpc() {
            return Math.round(d3.quantileSorted(this.epcs, 0.5) * 100) / 100
        },
        meanEpc() {
            return Math.round(d3.mean(this.epcs) * 100) / 100
        },
        epcDeviance() {
            return Math.round(d3.deviation(this.epcs) * 100) / 100
        },
        epcTile() {
            return (d3.bisect(this.epcs, this.targetEpc) / this.epcs.length) * 100
        },
        years() {
            const array = this.avmTransaction.reference_listings.map(
                ({ f_construction_year }) => f_construction_year
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetYear() {
            return this.avmTransaction.input_data.f_construction_year
        },
        medianYear() {
            return Math.round(d3.quantileSorted(this.years, 0.5))
        },
        meanYear() {
            return Math.round(d3.mean(this.years))
        },
        yearDeviance() {
            return Math.round(d3.deviation(this.years))
        },
        yearTile() {
            return (d3.bisect(this.years, this.targetYear) / this.years.length) * 100
        },
        livingAreas() {
            const array = this.avmTransaction.reference_listings.map(
                ({ f_living_area }) => f_living_area
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetLivingArea() {
            return this.avmTransaction.input_data.f_living_area
        },
        medianLiving() {
            return Math.round(d3.quantileSorted(this.livingAreas, 0.5) * 100) / 100
        },
        meanLiving() {
            return Math.round(d3.mean(this.livingAreas) * 100) / 100
        },
        livingDeviance() {
            return Math.round(d3.deviation(this.livingAreas) * 100) / 100
        },
        livingTile() {
            return (
                (d3.bisect(this.livingAreas, this.targetLivingArea) /
                    this.livingAreas.length) *
                100
            )
        },
        parcelAreas() {
            const array = this.avmTransaction.reference_listings.map(
                ({ f_parcel_area }) => f_parcel_area
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetParcelArea() {
            return this.avmTransaction.input_data.f_parcel_area
        },
        medianParcel() {
            return Math.round(d3.quantileSorted(this.parcelAreas, 0.5) * 100) / 100
        },
        meanParcel() {
            return Math.round(d3.mean(this.parcelAreas) * 100) / 100
        },
        parcelDeviance() {
            return Math.round(d3.deviation(this.parcelAreas) * 100) / 100
        },
        parcelTile() {
            return (
                (d3.bisect(this.parcelAreas, this.targetParcelArea) /
                    this.parcelAreas.length) *
                100
            )
        },
        buildingAreas() {
            const array = this.avmTransaction.reference_listings.map(
                ({ f_building_area }) => f_building_area
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetBuildingArea() {
            return this.avmTransaction.input_data.f_building_area
        },
        medianBuilding() {
            return Math.round(d3.quantileSorted(this.buildingAreas, 0.5) * 100) / 100
        },
        meanBuilding() {
            return Math.round(d3.mean(this.buildingAreas) * 100) / 100
        },
        buildingDeviance() {
            return Math.round(d3.deviation(this.buildingAreas) * 100) / 100
        },
        buildingTile() {
            return (
                (d3.bisect(this.buildingAreas, this.targetBuildingArea) /
                    this.buildingAreas.length) *
                100
            )
        },
        gardenAreas() {
            const array = this.avmTransaction.reference_listings.map(
                ({ f_garden_area }) => f_garden_area
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetGardenArea() {
            return this.avmTransaction.input_data.f_garden_area
        },
        medianGarden() {
            return Math.round(d3.quantileSorted(this.gardenAreas, 0.5) * 100) / 100
        },
        meanGarden() {
            return Math.round(d3.mean(this.gardenAreas) * 100) / 100
        },
        gardenDeviance() {
            return Math.round(d3.deviation(this.gardenAreas) * 100) / 100
        },
        gardenTile() {
            return (
                (d3.bisect(this.gardenAreas, this.targetGardenArea) /
                    this.gardenAreas.length) *
                100
            )
        },
        touchingSides() {
            const array = this.avmTransaction.reference_listings.map(
                ({ f_touching_sides }) => f_touching_sides
            )
            array.sort((a, b) => a - b)
            return array
        },
        targetTouchingSides() {
            return this.avmTransaction.input_data.f_touching_sides
        },
        medianSides() {
            return d3.quantileSorted(this.touchingSides, 0.5)
        },
        meanSides() {
            return d3.mean(this.touchingSides)
        },
        sidesDeviance() {
            return d3.deviation(this.touchingSides)
        },
        sidesTile() {
            return (
                (d3.bisect(this.touchingSides, this.targetTouchingSides) /
                    this.touchingSides.length) *
                100
            )
        },
        warnings() {
            const warnings = []

            if (this.epcTile < 5 || this.epcTile > 95) {
                warnings.push('features.f_epc')
            }
            if (this.yearTile < 5 || this.yearTile > 95) {
                warnings.push('features.f_construction_year')
            }
            if (this.livingTile < 5 || this.livingTile > 95) {
                warnings.push('features.f_living_area')
            }

            if (!this.isApartment) {
                if (this.parcelTile < 5 || this.parcelTile > 95) {
                    warnings.push('features.f_parcel_area')
                }
                if (this.buildingTile < 5 || this.buildingTile > 95) {
                    warnings.push('features.f_building_area')
                }
                if (this.gardenTile < 5 || this.gardenTile > 95) {
                    warnings.push('features.f_garden_area')
                }
                if (this.sidesTile < 5 || this.sidesTile > 95) {
                    warnings.push('touching_sides')
                }
            }

            return warnings
        },
    },
    watch: {
        deviations: {
            handler() {
                let dev = 0
                for (const [key, value] of Object.entries(this.deviations)) {
                    dev += +value
                }
                this.updateDeviation(dev)
            },
            deep: true,
        },
    },
    mounted() {
        radarchart('#radar', this.radarChartData(), this.radarChartOptions)
    },
    methods: {
        radarChartData() {
            const chartData = [
                { axis: this.$t('features.f_epc'), value: 100 - this.epcTile },
                { axis: this.$t('features.f_construction_year'), value: this.yearTile },
                { axis: this.$t('features.f_living_area'), value: this.livingTile },
            ]

            if (!this.isApartment) {
                chartData.push({
                    axis: this.$t('features.f_parcel_area'),
                    value: this.parcelTile,
                })
                chartData.push({
                    axis: this.$t('features.f_building_area'),
                    value: this.buildingTile,
                })
                chartData.push({
                    axis: this.$t('features.f_garden_area'),
                    value: this.gardenTile,
                })
                chartData.push({
                    axis: this.$t('touching_sides'),
                    value: 100 - this.sidesTile,
                })
            }

            return [chartData]
        },
        withSpaces(number) {
            return utils.numberWithSpaces(number)
        },
    },
}
</script>
<style lang="scss" scoped>
.valuations-prices {
    span {
        flex-basis: 50%;
    }
}

.is-avm-grid {
    display: grid;
    grid-template-columns: minmax(700px, auto) minmax(75px, auto);
    align-items: center;
}
</style>
