/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ruler': {
    width: 19,
    height: 20,
    viewBox: '0 0 19 20',
    data: '<path class="st0" d="M18.3,4.3l-3.1-3.1c-0.5-0.5-1.5-0.5-2,0L12.4,2l1.3,1.2l0,0L15,4.5c0.2,0.2,0.2,0.6,0,0.9 c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2l-0.9-0.9l-0.8-0.9l0,0l-0.8-0.8l-0.9,0.9l1.1,1.1L12.1,5l0.3,0.3 c0.2,0.2,0.2,0.6,0,0.9c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2l-0.3-0.3l-0.6-0.6L9.9,4.5L9,5.4l1.3,1.3l0.2,0.2L11.6,8 c0.2,0.2,0.2,0.6,0,0.8C11.4,8.9,11.3,9,11.1,9c-0.2,0-0.3-0.1-0.4-0.2L9.9,7.9L9,7l0,0L8.1,6.2L7.3,7.1l0.8,0.8l0.5,0.5L9,8.8 C9.2,9,9.2,9.4,9,9.6C8.8,9.7,8.7,9.8,8.5,9.8c-0.2,0-0.3-0.1-0.4-0.2L7.8,9.3L7.3,8.8L6.4,7.9L5.5,8.8l0.8,0.8l0.9,0.9l0,0l0.9,0.8 c0.2,0.2,0.2,0.6,0,0.8c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2l-1-1l-0.7-0.7L4.7,9.7l-0.9,0.9l1.3,1.3l0.4,0.4 c0.2,0.2,0.2,0.6,0,0.9c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2l-0.5-0.4L3,11.4l-0.9,0.9l1.5,1.4l1.1,1.1 c0.2,0.2,0.2,0.6,0,0.8c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2l-1-1l-0.1-0.1l-1.5-1.5l-0.7,0.7c-0.3,0.3-0.4,0.6-0.4,1 c0,0.4,0.1,0.7,0.4,1L3.6,19c0.2,0.3,0.6,0.4,1,0.4h0c0.4,0,0.8-0.2,1-0.4L18.3,6.3C18.9,5.8,18.9,4.9,18.3,4.3z"/>'
  }
})
