<i18n>
{
    "en-BE": {
        "all": "All customers",
        "unassigned": "Unassigned",
        "specialist": "Valuation Specialist",
        "total": "Total",
        "showFullList": "Show full list of valuers",
        "hideFullList": "Show only assigned valuers"
    },
    "fr-BE": {
        "all": "Tous les clients",
        "unassigned": "Non assigné",
        "specialist": "Valuation Specialist",
        "total": "Total",
        "showFullList": "Afficher la liste complète des experts",
        "hideFullList": "Afficher uniquement les experts assignés"
    },
    "nl-BE": {
        "all": "Alle klanten",
        "unassigned": "Niet toegewezen",
        "specialist": "Valuation Specialist",
        "total": "Totaal",
        "showFullList": "Toon de volledige lijst van experts ",
        "hideFullList": "Toon alleen de toegewezen experts"
    }
}
</i18n>
<template>
    <div>
        <b-tabs
            v-if="isDispatchDashboard"
            v-model="workloadTab"
            position="is-centered"
            class="block"
        >
            <b-tab-item value="0" :label="$t('all')" />
            <b-tab-item
                v-for="bank in dispatchedBanks"
                :key="`tab_${bank}`"
                :value="bank"
                :label="bank"
            />
        </b-tabs>
        <div class="workload is-flex is-flex-direction-column is-align-items-flex-end">
            <div :class="`workload-grid-${workloadPerValuer.maxBD}`">
                <p class="mr-4 has-text-weight-medium has-text-right">
                    {{ $t('specialist') }}
                </p>
                <p
                    v-for="BD in workloadPerValuer.maxBD + 1"
                    :key="`workload_header_${BD - 1}bd`"
                    class="ml-1 mr-1 has-text-centered has-text-weight-medium"
                >
                    D{{ BD - 1 }}{{ BD - 1 == 4 ? '+' : '' }}
                </p>
                <p class="ml-1 mr-1 has-text-centered has-text-weight-medium">
                    {{ $t('total') }}
                </p>
            </div>
            <div
                v-for="specialist in Object.keys(workloadPerValuer.wl)"
                :key="`workload_${specialist}`"
                :class="
                    `workload-grid-${
                        workloadPerValuer.maxBD
                    } ${specialist.toLowerCase() === 'unassigned' && 'first-row'}`
                "
            >
                <p class="mr-4 has-text-right">
                    {{
                        specialist.toLowerCase() === 'unassigned'
                            ? $t('unassigned')
                            : specialist
                    }}
                </p>
                <p
                    v-for="BD in workloadPerValuer.maxBD + 1"
                    :key="`workload_${specialist}_${BD - 1}bd`"
                    class="ml-1 mr-1 has-text-centered"
                    :class="{
                        'has-text-weight-bold has-text-primary': workloadPerValuer.wl[
                            specialist
                        ].hasOwnProperty(`D${BD - 1}`),
                    }"
                >
                    {{ workloadPerValuer.wl[specialist][`D${BD - 1}`] || '-' }}
                </p>
                <p
                    class="ml-1 mr-1 has-text-centered"
                    :class="{
                        'has-text-weight-bold has-text-danger':
                            workloadPerValuer.wl[specialist].total > 0,
                    }"
                >
                    {{ workloadPerValuer.wl[specialist].total }}
                </p>
            </div>
            <div
                v-for="(bank, index) in Object.keys(workloadPerValuer.totalPerBank)"
                :key="`workload_${bank}`"
                :class="
                    `workload-grid-${workloadPerValuer.maxBD} ${!index &&
                        'first-bank-row'}`
                "
            >
                <p class="mr-4 has-text-right">
                    {{ bank }}
                </p>
                <p
                    v-for="BD in workloadPerValuer.maxBD + 1"
                    :key="`workload_${specialist}_${BD - 1}bd`"
                    class="ml-1 mr-1 has-text-centered"
                    :class="{
                        'has-text-weight-bold has-text-primary': workloadPerValuer.totalPerBank[
                            bank
                        ].hasOwnProperty(`D${BD - 1}`),
                    }"
                >
                    {{ workloadPerValuer.totalPerBank[bank][`D${BD - 1}`] || '-' }}
                </p>
                <p
                    class="ml-1 mr-1 has-text-centered"
                    :class="{
                        'has-text-weight-bold has-text-danger':
                            workloadPerValuer.totalPerBank[bank].total > 0,
                    }"
                >
                    {{ workloadPerValuer.totalPerBank[bank].total }}
                </p>
            </div>
            <div :class="`workload-grid-${workloadPerValuer.maxBD} last-row`">
                <p class="mr-4 has-text-weight-medium has-text-right">
                    {{ $t('total') }}
                </p>
                <p
                    v-for="BD in workloadPerValuer.maxBD + 1"
                    :key="`workload_totals_${BD - 1}bd`"
                    class="ml-1 mr-1 has-text-centered"
                    :class="{
                        'has-text-weight-bold':
                            workloadPerValuer.totalPerBD[`D${BD - 1}`],
                    }"
                >
                    {{ workloadPerValuer.totalPerBD[`D${BD - 1}`] || '-' }}
                </p>
                <p
                    class="ml-1 mr-1 has-text-centered has-text-weight-bold has-text-danger"
                >
                    {{ workloadPerValuer.totalSubmitted }}
                </p>
            </div>
            <b-button
                type="is-primary"
                class="is-align-self-center mt-3"
                @click="toggleFullList"
            >
                {{ seeFullList ? $t('hideFullList') : $t('showFullList') }}
            </b-button>
        </div>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'

export default {
    name: 'ValuerWorkload',
    props: {
        valuers: {
            type: Array,
            required: true,
        },
        requests: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            seeFullList: false,
            workloadTab: '0',
        }
    },
    computed: {
        isDispatchDashboard() {
            return (
                process.env.VUE_APP_DISPATCHED_DEPLOYMENTS &&
                process.env.VUE_APP_DISPATCHED_DEPLOYMENTS !== ''
            )
        },
        dispatchedBanks() {
            if (!this.isDispatchDashboard) return []
            const returnArray = []

            this.requests.forEach((request) => {
                let bank = request.valuation_request_ref.split('_')[0]
                if (
                    request.status === 'submitted' &&
                    request.last_submission_at &&
                    !returnArray.includes(bank)
                ) {
                    returnArray.push(bank)
                }
            })

            return returnArray
        },
        workloadPerValuer() {
            const now = new Date()
            const returnObject = {
                wl: {},
                maxBD: 0,
                totalPerBD: {},
                totalSubmitted: 0,
                totalPerBank: {},
            }

            if (this.seeFullList) {
                this.valuers.forEach((valuer) => {
                    returnObject.wl[valuer.username] = {
                        total: 0,
                    }
                })
            }

            this.requests.forEach((request) => {
                const bank = this.isDispatchDashboard
                    ? request.valuation_request_ref.split('_')[0]
                    : null

                if (
                    request.status === 'submitted' &&
                    request.last_submission_at &&
                    (!bank || !(this.workloadTab !== '0') || bank === this.workloadTab)
                ) {
                    const assignee = request.valuer.assigned
                        ? request.valuer.username
                        : 'unassigned'

                    if (!returnObject.wl.hasOwnProperty(assignee)) {
                        returnObject.wl[assignee] = {
                            total: 0,
                        }
                    }

                    const requestDay = new Date(request.last_submission_at)
                    const diffDays = Math.min(
                        utils.dateDiffInBusinessDays(requestDay, now),
                        4
                    )

                    returnObject.maxBD = Math.max(diffDays, returnObject.maxBD)

                    if (returnObject.wl[assignee].hasOwnProperty(`D${diffDays}`)) {
                        returnObject.wl[assignee][`D${diffDays}`]++
                    } else {
                        returnObject.wl[assignee][`D${diffDays}`] = 1
                    }

                    if (returnObject.totalPerBD.hasOwnProperty(`D${diffDays}`)) {
                        returnObject.totalPerBD[`D${diffDays}`]++
                    } else {
                        returnObject.totalPerBD[`D${diffDays}`] = 1
                    }

                    returnObject.wl[assignee].total++
                    returnObject.totalSubmitted++

                    if (this.isDispatchDashboard && this.workloadTab === '0') {
                        if (!returnObject.totalPerBank.hasOwnProperty(bank)) {
                            returnObject.totalPerBank[bank] = { total: 0 }
                        }

                        if (
                            returnObject.totalPerBank[bank].hasOwnProperty(`D${diffDays}`)
                        ) {
                            returnObject.totalPerBank[bank][`D${diffDays}`]++
                        } else {
                            returnObject.totalPerBank[bank][`D${diffDays}`] = 1
                        }

                        returnObject.totalPerBank[bank].total++
                    }
                }
            })

            return returnObject
        },
    },
    methods: {
        toggleFullList() {
            this.seeFullList = !this.seeFullList
        },
    },
}
</script>
<style lang="scss" scoped>
@mixin gridColumnsGenerator($maxAmount, $baseClass) {
    @for $i from 0 to $maxAmount {
        .#{$baseClass}-#{$i} {
            display: grid;
            grid-template-columns: auto repeat($i + 1, 3rem) 5rem;
            // grid-template-columns: 25rem repeat($i + 1, 1fr);

            order: 2;

            &.first-bank-row {
                border-top: 1px solid lightgrey;
                align-self: stretch;
            }

            &.last-row {
                order: 998;
                border-top: 1px solid lightgrey;
                align-self: stretch;
            }

            &.first-row {
                order: 1;
            }
        }
    }
}

.workload {
    @include gridColumnsGenerator(6, 'workload-grid');

    [class^='workload-grid-'] {
        &:not(:first-child):hover {
            background: rgba(#0096ff, 0.1);
            width: 100%;
        }

        &:first-child {
            align-self: stretch;
            border-bottom: 1px solid lightgrey;
            order: 0;
        }
    }

    width: max-content;
    margin-left: auto;
    margin-right: auto;

    button {
        order: 999;
    }
}
</style>
