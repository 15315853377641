<template>
    <div id="costplot">
        <cost-plot
            v-model="chartData"
            :point="point"
            :values="values"
            :part="part"
            :left="left_margin"
            :right="right_margin"
        />
        <div v-show="part === 2" class="left-gradient"></div>
        <div v-show="part === 2" class="right-gradient"></div>
        <div
            v-show="chartData.tooltipPosition !== '-1px'"
            :style="`left: calc(${chartData.tooltipPosition} - 35px);`"
            class="chart-tooltip"
        >
            {{
                valuation.toLocaleString('fr-BE', {
                    style: 'currency',
                    currency: 'EUR',
                    maximumSignificantDigits: 3,
                })
            }}
        </div>
        <div
            v-show="part === 2 && chartData.leftLinePosition !== '-1px'"
            :style="`left: ${chartData.leftLinePosition};`"
            class="line left"
        ></div>
        <div
            v-show="part === 2 && chartData.rightLinePosition !== '-1px'"
            :style="`left: ${chartData.rightLinePosition};`"
            class="line right"
        ></div>
    </div>
</template>

<script>
import cost_plot from './cost_plot'
import { mapGetters } from 'vuex'

export default {
    components: {
        'cost-plot': cost_plot,
    },
    props: {
        estimation_results_data: Object,
        input_data: Object,
        part: Number,
    },
    data: function() {
        return {
            // Processed data for graphs
            point: [{ x: 0, y: 0 }],
            values: [
                { x: 1, y: 1 },
                { x: 2, y: 2 },
            ],
            // Flags
            calculated: false,
            chartData: {
                tooltipPosition: '-1px',
                leftLinePosition: '-1px',
                rightLinePosition: '-1px',
            },
        }
    },

    computed: {
        ...mapGetters('Address', ['addressInfo', 'getValuation']),
        valuation() {
            return this.getValuation.market_value
                ? this.getValuation.market_value
                : this.addressInfo.estimationResultsData.estimation.asking_price_q50
        },
        rounded_estimation() {
            return Math.round(this.valuation / 1000) * 1000
        },
        left_margin() {
            return (
                Math.round(
                    (this.valuation -
                        this.addressInfo.estimationResultsData.estimation
                            .asking_price_q25) /
                        1000
                ) * 1000
            )
        },
        right_margin() {
            return (
                Math.round(
                    (this.valuation -
                        this.addressInfo.estimationResultsData.estimation
                            .asking_price_q50) /
                        1000
                ) * 1000
            )
        },
    },
    created() {
        this.values = this.addressInfo.estimationResultsData.reference_listings.map(
            (ref_point) => {
                return { x: ref_point.prijs, y: this.randn_bm(-5, 5, 1) }
            }
        )
        this.point[0].x = this.rounded_estimation
        this.point[0].y = 0
        // this.point_to_estimate.parcel_area = [{'x': this.input_data.prijs, 'y':0}]
        // console.log(this.point_to_estimate.parcel_area)
        this.calculated = true
    },
    methods: {
        randn_bm(min, max, skew) {
            var u = 0,
                v = 0
            while (u === 0) u = Math.random() //Converting [0,1) to (0,1)
            while (v === 0) v = Math.random()
            let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v)

            num = num / 10.0 + 0.5 // Translate to 0 -> 1
            if (num > 1 || num < 0) num = randn_bm(min, max, skew) // resample between 0 and 1 if out of range
            num = Math.pow(num, skew) // Skew
            num *= max - min // Stretch to fill range
            num += min // offset to min
            return num
        },
    },
}
</script>

<style lang="scss">
#costplot {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 150px;
    position: relative;
}
#costplot > div {
    height: 150px;
    position: relative;
    // transition: .4s all;
    // overflow-y: overlay
}
#costplot {
    .chart-tooltip {
        height: 30px;
        position: absolute;
        top: -33px;
        // transition: .4s all;
    }
    .line {
        // border-right: 2px dashed #ff3100;
        background-image: linear-gradient(#f9fafc 50%, rgb(255, 49, 0) 50%);
        background-position: right;
        background-size: 5px 10px;
        background-repeat: repeat-y;
        height: 80px;
        width: 2px;
        position: absolute;
        bottom: -85px;
        transition: 0.4s all;
    }
}
.chart2 {
    #costplot {
        padding: 0;
        margin: 0;
        width: 100%;
        // height: 250px;
        position: relative;
        .left-gradient {
            z-index: 10000;
            position: absolute;
            width: 150px;
            top: 0;
            left: -10px;
            background-image: -webkit-linear-gradient(right, transparent, #f9fafc 50%);
            transition: 0.4s all;
        }
        .right-gradient {
            z-index: 10000;
            position: absolute;
            width: 150px;
            top: 0;
            right: -10px;
            background-image: -webkit-linear-gradient(left, transparent, #f9fafc 50%);
            transition: 0.4s all;
        }
    }
    #costplot > div {
        // height: 250px;
        // position: relative;
        z-index: 8;
    }
}
</style>

<style lang="scss" scoped>
.chart-tooltip {
    position: absolute;
    top: -30px;
    left: -1000000px;
    width: 70px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 6px 0 rgba(0, 47, 80, 0.1);
    background-color: rgba(0, 24, 55, 0.9);
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    border-radius: 5px;
    background-color: rgba(0, 24, 55, 0.9);
    z-index: 100;
    &::after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: calc(50% - 6px);
        top: 30px;
        border: 6px solid;
        border-color: rgba(0, 24, 55, 0.9) transparent transparent transparent;
    }
}
</style>
