<i18n>
{
  "en-BE": {
    "f_lng": "Longitude",
    "f_lat": "Latitude",
    "parcel_area": "Parcel area",
    "building_area": "Building area",
    "garden_area": "Garden area",
    "nbr_sides": "Number of facades",
    "nbr_sides_message": "Select one",
    "nbr_sides_attached": "Attached",
    "nbr_sides_semi": "Semi detached ",
    "nbr_sides_detached": "Detached",
    "field_required": "This field is required",
    "number_only": "Please enter only numeric values"
  },
  "nl-BE": {
    "f_lng": "Longitude",
    "f_lat": "Latitude",
    "parcel_area": "Oppervlakte perceel",
    "building_area": "Bebouwde oppervlakte",
    "garden_area": "Oppervlakte tuin",
    "nbr_sides": "Aantal gevels",
    "nbr_sides_message": "Kies een",
    "nbr_sides_attached": "Gesloten bebouwing",
    "nbr_sides_semi": "Half-open bebouwing",
    "nbr_sides_detached": "Open bebouwing",
    "field_required": "Dit veld is verplicht",
    "number_only": "Gelieve enkel cijfers in te vullen"
  },
  "fr-BE": {
    "f_lng": "Longitude",
    "f_lat": "Latitude",
    "parcel_area": "Surface de la parcelle",
    "building_area": "Surface du bâtiment",
    "garden_area": "Surface du jardin",
    "nbr_sides": "Nombre de façades",
    "nbr_sides_message": "Sélectionnez",
    "nbr_sides_attached": "2 façades",
    "nbr_sides_semi": "3 façades",
    "nbr_sides_detached": "4 façades",
    "field_required": "Ce champ est obligatoire",
    "number_only": "Veuillez entrer uniquement des chiffres"
  }
}
</i18n>

<template>
    <!-- cannot find section -->
    <div class="column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet">
        <div class="columns is-multiline is-mobile is-variable is-6">
            <div
                class="column is-6-desktop is-12-mobile is-flex is-align-content-flex-end"
            >
                <b-field
                    class="is-flex-grow-2"
                    custom-class="is-small-tooltip"
                    :type="{
                        'is-danger': $v.form.f_parcel_area.$error,
                    }"
                    :message="[!$v.form.f_parcel_area.decimal ? $t('number_only') : null]"
                >
                    <template slot="label">
                        {{ $t('parcel_area') }}
                        <b-tooltip
                            class="ml-2 mb-1"
                            type="is-primary"
                            :label="$t('parcel_area')"
                        >
                            <div
                                class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                            >
                                ?
                            </div>
                        </b-tooltip>
                    </template>
                    <b-input
                        v-model="form.f_parcel_area"
                        size="is-medium"
                        :disabled="!isDraft"
                        @input="$v.form.f_parcel_area.$touch()"
                        @blur="$v.form.f_parcel_area.$touch()"
                    ></b-input>
                </b-field>
                <span class="is-align-self-flex-start mt-5 pt-5 ml-2 is-size-5 is-unit">
                    {{ $t('unit.m2') }}
                </span>
            </div>
            <div
                class="column is-6-desktop is-12-mobile is-flex is-align-content-flex-end"
            >
                <b-field
                    class="is-flex-grow-2"
                    custom-class="is-small-tooltip"
                    :type="{
                        'is-danger': $v.form.f_building_area.$error,
                    }"
                    :message="[
                        !$v.form.f_building_area.decimal ? $t('number_only') : null,
                    ]"
                >
                    <template slot="label">
                        {{ $t('building_area') }}
                        <b-tooltip
                            class="ml-2 mb-1"
                            type="is-primary"
                            :label="$t('building_area')"
                        >
                            <div
                                class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                            >
                                ?
                            </div>
                        </b-tooltip>
                    </template>
                    <b-input
                        v-model="form.f_building_area"
                        size="is-medium"
                        :disabled="!isDraft"
                        @input="$v.form.f_building_area.$touch()"
                        @blur="$v.form.f_building_area.$touch()"
                    ></b-input>
                </b-field>
                <span class="is-align-self-flex-start mt-5 pt-5 ml-2 is-size-5 is-unit">
                    {{ $t('unit.m2') }}
                </span>
            </div>
            <!--<div class="column is-6-desktop is-12-mobile">
                        <b-field
                            custom-class="is-small-tooltip"
                            :type="{
                                'is-danger': $v.getFeatures.f_garden_area.$error
                            }"
                            :message="[
                                {
                                    'numbers only': !$v.getFeatures
                                        .f_garden_area.decimal
                                },
                                {
                                    'required': !$v.getFeatures
                                        .f_garden_area.required
                                }
                            ]"
                        >
                            <template slot="label">
                                {{ $t('garden_area') }}
                                <b-tooltip
                                    class="ml-2 mb-1"
                                    type="is-primary"
                                    :label="$t('garden_area')"
                                >
                                    <div
                                        class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                                    >
                                        ?
                                    </div>
                                </b-tooltip>
                            </template>
                            <b-input
                                size="is-medium"
                                v-model.number="getFeatures.f_garden_area"
                                :disabled="!isDraft"
                                @blur="$v.getFeatures.f_garden_area.$touch()"
                                @input="$v.getFeatures.f_garden_area.$touch()"
                            ></b-input>
                        </b-field>
                    </div>-->
            <div class="column is-6-desktop is-12-mobile">
                <b-field custom-class="is-small-tooltip">
                    <template slot="label">
                        {{ $t('nbr_sides') }}
                        <b-tooltip
                            class="ml-2 mb-1"
                            type="is-primary"
                            :label="$t('nbr_sides')"
                        >
                            <div
                                class="is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"
                            >
                                ?
                            </div>
                        </b-tooltip>
                    </template>
                    <b-select
                        v-model="form.touching_sides"
                        :placeholder="$t('nbr_sides_message')"
                        :disabled="!isDraft"
                        size="is-medium"
                        expanded
                    >
                        <option :value="2">
                            {{ $t('nbr_sides_attached') }}
                        </option>
                        <option :value="3">
                            {{ $t('nbr_sides_semi') }}
                        </option>
                        <option :value="4">
                            {{ $t('nbr_sides_detached') }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <!-- new field here -->
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { decimal } from 'vuelidate/lib/validators'

export default {
    props: {
        valid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                f_parcel_area: null,
                f_building_area: null,
                touching_sides: null,
            },
        }
    },
    computed: {
        ...mapGetters('Address', ['getFeatures', 'getFullBuildingData']),
        ...mapGetters(['isDraft']),
    },
    validations: {
        form: {
            f_parcel_area: {
                decimal,
            },
            f_building_area: {
                decimal,
            },
        },
    },
    watch: {
        'form.f_parcel_area'(val) {
            this.SET_FEATURES({ f_parcel_area: parseFloat(val) })
        },
        'form.f_building_area'(val) {
            this.SET_FEATURES({ f_building_area: parseFloat(val) })
        },
        'form.touching_sides'(val) {
            this.SET_FEATURES({ f_touching_sides: 4 - val })
        },
        valid(val) {
            if (val) {
                this.$v.form.$touch()
            }
        },
    },
    mounted() {
        // updated separate key data from store
        for (const key in this.form) {
            this.form[key] = this.getFeatures[key] || null
        }
        this.$emit('notFound', this.$v.form.$invalid)
    },
    updated() {
        this.$emit('notFound', this.$v.form.$invalid)
    },
    methods: {
        ...mapMutations('Address', ['SET_FEATURES']),
    },
}
</script>

<style lang="scss" scoped></style>
