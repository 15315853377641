<template>
    <div>
        <div id="toc" class="card">
            <div class="card-content content is-small has-text-centered">
                <h4>{{ $t('Contents') }}</h4>
                <div class="has-text-left">
                    <ul class="mb-1">
                        <li>
                            <a href="#page-cover">{{ $t('front.title') }}</a>
                        </li>
                    </ul>
                    <ol class="1 mt-1">
                        <li>
                            <a href="#page-general">{{ $t('section.general_info') }}</a>
                        </li>
                        <li>
                            <a href="#page-building">{{ $t('section.building') }}</a>
                        </li>
                        <li>
                            <a href="#page-valuation">{{ $t('section.valuations') }}</a>
                        </li>
                        <li>
                            <a href="#page-reference-properties-location">
                                {{ $t('section.reference_properties_location') }}
                            </a>
                        </li>
                        <li>
                            <a href="#page-reference-properties-characteristics">
                                {{ $t('section.reference_properties_characteristics') }}
                            </a>
                        </li>
                        <li>
                            <a href="#page-flood">{{ $t('section.flood') }}</a>
                        </li>
                        <li>
                            <a href="#page-faq">{{ $t('section.faq') }}</a>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <div id="report-vue" class="has-text-centered is-size-7">
            <div id="page-cover" class="page cover-page is-flex is-flex-direction-column">
                <div class="content section">
                    <div class="columns is-multiline">
                        <div class="column is-12 is-centered mt-10">
                            <img
                                width="300px"
                                src="@/shared/assets/icons/rockestate-logo-square-dark.svg"
                            />
                        </div>
                        <div class="column is-12 mt-10 has-text-centered">
                            <div class="is-inline is-size-4">
                                {{ $t('front.title') }}
                            </div>
                            <div
                                class="is-inline is-size-4 has-text-weight-bold has-text-primary"
                            >
                                {{ formatted_date }}
                            </div>
                        </div>
                        <div class="column is-12">
                            <p class="title has-text-weight-bold">
                                {{ getFullStore.Address.data.address_text.full_address }}
                            </p>
                        </div>
                        <div class="column is-4 mt-6">
                            <p class="">{{ $t('request.bank') }}</p>
                            <p class="has-text-weight-bold has-text-black">
                                {{ bank_name }}
                            </p>
                        </div>
                        <div class="column is-4 mt-6">
                            <p class="">{{ $t('valuation.valuer') }}</p>
                            <p class="has-text-weight-bold has-text-black">
                                {{ valuer_name }}
                            </p>
                        </div>
                        <div class="column is-4 mt-6">
                            <p class="">{{ $t('valuation.monitor') }}</p>
                            <p class="has-text-weight-bold has-text-black">
                                {{
                                    getValuation.type === 'dvm'
                                        ? 'Pietjan Vandooren'
                                        : '-'
                                }}
                            </p>
                        </div>

                        <div class="column is-4 mt-6">
                            <p class="">{{ $t('request.purpose') }}</p>
                            <p class="has-text-weight-bold has-text-black">
                                New loan application
                            </p>
                        </div>
                        <div class="column is-4 mt-6">
                            <p class="">{{ $t('valuation.type') }}</p>
                            <p class="has-text-weight-bold has-text-black">
                                {{ $t(`value.${getValuation.type}`) }}
                            </p>
                        </div>
                        <div class="column is-4 mt-6">
                            <p>{{ $t('request.references') }}</p>
                            <p>
                                <span>Rock.estate:</span>
                                <span class="has-text-weight-bold has-text-black">
                                    {{ getFullStore.valuation_request_ref }}
                                </span>
                            </p>
                            <p>
                                <span>{{ bank_name }}:</span>
                                <span class="has-text-weight-bold has-text-black">
                                    {{ getFullStore.customer_ref }}
                                </span>
                            </p>
                        </div>

                        <div class="column is-12 mt-6">
                            <p class="is-7">
                                {{ $t(`disclaimer.${getValuation.type}`) }}
                            </p>
                        </div>
                    </div>
                </div>
                <report-footer></report-footer>
            </div>
            <div id="page-general" class="page is-flex is-flex-direction-column">
                <div class="section content pt-5 px-5 pb-2">
                    <report-header />
                    <div class="columns is-multiline">
                        <div class="column is-4">
                            <div class="image is-square mb-2">
                                <GmapStreetViewPanorama
                                    v-if="getView.gsv_available"
                                    style="width: 100%; height: 100%"
                                    class="pano has-ratio"
                                    :pov="{
                                        heading: getView.gsv_ph,
                                        pitch: getView.gsv_pp,
                                    }"
                                    :zoom="getView.gsv_pz"
                                    :pano="getView.gsv_p"
                                    :options="{
                                        disableDefaultUI: true,
                                        fullscreenControl: false,
                                        addressControl: false,
                                        scrollwheel: false,
                                        panningGesturesEnabled:
                                            $route.query.static === 'true',
                                        clickToGo: false,
                                    }"
                                ></GmapStreetViewPanorama>
                                <div v-else class="card has-ratio">
                                    <div class="card-content">
                                        <div class="content">
                                            {{ $t('streetview unavailable') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="image is-square my-2">
                                <re-map
                                    :building-id="getFeatures.building_id"
                                    :parcel-ids="getFeatures.parcel_ids"
                                    :level="getFeatures.level"
                                    :center="{
                                        lat: getFeatures.f_lat,
                                        lng: getFeatures.f_lng,
                                    }"
                                    :map-scene2d="get_map_scene2d"
                                    :read-only="true"
                                    :focus-layer="'building'"
                                    class="has-ratio"
                                    :size="'small'"
                                    :layers-control-visible="false"
                                    :flood-visible="false"
                                    :options="map_options"
                                >
                                    <template slot="custom-layer">
                                        <rotating-marker
                                            v-if="getView.gsv_available"
                                            :lat-lng="[getView.gsv_lat, getView.gsv_lng]"
                                            :heading="getView.gsv_ph"
                                            :scale-y="getView.gsv_pz"
                                            :icon="icon"
                                        ></rotating-marker>
                                    </template>
                                </re-map>
                            </div>
                            <div class="image is-square my-2">
                                <re-map
                                    :building-id="getFeatures.building_id"
                                    :parcel-ids="getFeatures.parcel_ids"
                                    :level="getFeatures.level"
                                    :map-scene2d="get_map_scene2d"
                                    :center="{
                                        lat: getFeatures.f_lat,
                                        lng: getFeatures.f_lng,
                                    }"
                                    :read-only="true"
                                    :focus-layer="'building'"
                                    class="has-ratio"
                                    :size="'small'"
                                    :layers-control-visible="false"
                                    :options="map_options"
                                    :aerial-tile-visible="false"
                                    :cadastral-tile-visible="false"
                                ></re-map>
                            </div>
                            <div class="image is-3by2 mt-2">
                                <renderer
                                    v-if="
                                        getFullStore.Address.data.features.level === '3'
                                    "
                                    class="has-ratio"
                                    :building-id="
                                        getFullStore.Address.data.features.building_id
                                    "
                                    :map-scene3d="get_map_scene3d"
                                    :show-attributions="false"
                                    :auto-rotate="$route.query.static !== 'true'"
                                    @update:mapScene3d="SET_MAP_SCENE3D($event)"
                                ></renderer>
                                <div v-else class="card has-ratio">
                                    <div class="card-content">
                                        <div class="content">
                                            {{ $t('3d model unavailable') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-8">
                            <article class="message is-dark">
                                <div class="message-body">
                                    <div class="level">
                                        <div
                                            class="level-left has-text-weight-bold has-text-black"
                                        >
                                            {{ $t('valuation.market_value') }}
                                        </div>
                                        <div
                                            class="level-right has-text-weight-bold has-text-black"
                                        >
                                            {{
                                                getValuation.market_value
                                                    ? getValuation.market_value.toLocaleString(
                                                          'fr-BE',
                                                          {
                                                              maximumSignificantDigits: 3,
                                                              currency: 'EUR',
                                                              style: 'currency',
                                                          }
                                                      )
                                                    : ''
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        v-if="packageType === 'renovation_light'"
                                        class="level"
                                    >
                                        <div
                                            class="level-left has-text-weight-bold has-text-black"
                                        >
                                            {{
                                                $t(
                                                    'valuation.market_value_post_renovation'
                                                )
                                            }}
                                        </div>
                                        <div
                                            class="level-right has-text-weight-bold has-text-black"
                                        >
                                            {{
                                                getValuation.market_value_post_renovation
                                                    ? getValuation.market_value_post_renovation.toLocaleString(
                                                          'fr-BE',
                                                          {
                                                              maximumSignificantDigits: 3,
                                                              currency: 'EUR',
                                                              style: 'currency',
                                                          }
                                                      )
                                                    : ''
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <report-section
                                :name="'general_info'"
                                :filter-building-type="getFeatures.f_building_type"
                                :filter-null="filterNull"
                            ></report-section>
                            <report-section
                                :name="'parcels_and_buildings'"
                                :filter-building-type="getFeatures.f_building_type"
                                :filter-null="filterNull"
                            ></report-section>
                            <div class="px-6 py-2">
                                <div class="image is-square">
                                    <re-map
                                        :building-id="getFeatures.building_id"
                                        :parcel-ids="getFeatures.parcel_ids"
                                        :level="getFeatures.level"
                                        :center="{
                                            lat: getFeatures.f_lat,
                                            lng: getFeatures.f_lng,
                                        }"
                                        :map-scene2d="get_map_scene2d"
                                        :read-only="true"
                                        :focus-layer="'selected'"
                                        class="has-ratio leaflet-transparent"
                                        :size="'medium'"
                                        :layers-control-visible="false"
                                        :options="map_options"
                                        :flood-visible="false"
                                        :aerial-tile-visible="false"
                                    ></re-map>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <report-footer :page-number="1"></report-footer>
            </div>
            <div id="page-building" class="page is-flex is-flex-direction-column">
                <div class="section content pt-5 px-5 pb-2">
                    <report-header />
                    <report-section
                        :name="'building'"
                        :columns="2"
                        :filter-building-type="getFeatures.f_building_type"
                        :filter-null="filterNull"
                    ></report-section>
                    <report-section
                        :name="'building_structure'"
                        :columns="2"
                        :filter-building-type="getFeatures.f_building_type"
                        :filter-null="filterNull"
                    ></report-section>
                    <report-section
                        :name="'building_purpose_classification'"
                        :columns="2"
                        :filter-building-type="getFeatures.f_building_type"
                        :filter-null="filterNull"
                    ></report-section>
                </div>

                <report-footer :page-number="2"></report-footer>
            </div>
            <div id="page-building" class="page is-flex is-flex-direction-column">
                <div class="section content pt-5 px-5 pb-2 has-text-left">
                    <report-header />
                    <report-section
                        :name="'parcels'"
                        :columns="2"
                        :filter-building-type="getFeatures.f_building_type"
                        :filter-null="filterNull"
                    ></report-section>
                    <report-section
                        :name="'surroundings'"
                        :columns="2"
                        :filter-building-type="getFeatures.f_building_type"
                        :filter-null="filterNull"
                    ></report-section>
                    <div class="content mt-4">
                        <p class="has-text-weight-bold has-text-black is-size-6">
                            {{ $t('dvm_features.remarks') }}
                        </p>
                        <p class="is-size-7">{{ getDVMFeatures.remarks }}</p>
                    </div>
                </div>

                <report-footer :page-number="3"></report-footer>
            </div>
            <div id="page-valuation" class="page is-flex is-flex-direction-column">
                <div class="section content pt-5 px-5 pb-2 has-text-left">
                    <report-header />
                    <report-section
                        :name="'valuations'"
                        :orientation="'horizontal'"
                        :filter-building-type="getFeatures.f_building_type"
                        :filter-null="filterNull"
                    ></report-section>

                    <div class="content">
                        <p class="is-size-7">{{ $t('valuation.explanation_1') }}</p>
                        <p class="has-text-weight-bold has-text-black is-size-7">
                            {{ $t('valuation.explanation_2') }}
                        </p>
                        <p class="is-size-7">{{ $t('valuation.explanation_3') }}</p>
                        <p class="has-text-weight-bold has-text-black is-size-7">
                            {{ $t('valuation.explanation_4') }}
                        </p>
                        <p class="is-size-7">{{ $t('valuation.explanation_5') }}</p>
                        <div class="chart1">
                            <cost-plot
                                :part="2"
                                :estimation_results_data="
                                    addressInfo.estimationResultsData
                                "
                                :input_data="getFeatures"
                            />
                        </div>
                        <p class="is-size-7">{{ $t('valuation.explanation_6') }}</p>
                        <p
                            v-if="reconstruction_value_enabled()"
                            class="has-text-weight-bold has-text-black is-size-7"
                        >
                            {{ $t('disclaimer.reconstruction_value_title') }}
                        </p>
                        <p v-if="reconstruction_value_enabled()" class="is-size-7">
                            {{ $t('disclaimer.reconstruction_value') }}
                        </p>
                    </div>
                </div>

                <report-footer :page-number="4">
                    <p class="is-size-7">
                        (*) {{ $t('footnote.avm') }}: {{ avm_q50 }},
                        {{ $t('footnote.q25') }}: {{ avm_q25 }}, {{ $t('footnote.q75') }}:
                        {{ avm_q75 }}
                    </p>
                    <p v-if="packageType === 'renovation_light'" class="is-size-7">
                        (**) {{ $t('footnote.no_renovation_included') }}
                    </p>
                </report-footer>
            </div>
            <div
                id="page-reference-properties-location"
                class="page is-flex is-flex-direction-column"
            >
                <div class="section content pt-5 px-5 pb-2 has-text-left">
                    <report-header />
                    <report-section
                        :name="'reference_properties_location'"
                        :filter-building-type="getFeatures.f_building_type"
                        :filter-null="filterNull"
                    ></report-section>
                    <div class="content">
                        <p class="is-size-7">
                            {{
                                $t('reference_properties_location.explanation_1', {
                                    num_references: num_references,
                                    reference_radius: reference_radius,
                                })
                            }}
                        </p>
                        <p class="is-size-7">
                            {{ $t('reference_properties_location.explanation_2') }}
                        </p>
                    </div>
                    <div class="map">
                        <step-1 />
                    </div>
                </div>

                <report-footer :page-number="5"></report-footer>
            </div>
            <div
                id="page-reference-properties-characteristics"
                class="page is-flex is-flex-direction-column"
            >
                <div class="section content pt-5 px-5 pb-2 has-text-left">
                    <report-header />
                    <report-section
                        :name="'reference_properties_characteristics'"
                        :filter-building-type="getFeatures.f_building_type"
                        :filter-null="filterNull"
                    ></report-section>
                    <div class="content">
                        <p>
                            {{ $t('reference_properties_characteristics.explanation_1') }}
                        </p>
                        <div class="level">
                            <div class="level-left">
                                <div class="level-item">
                                    <div class="icon-text is-flex is-align-items-center">
                                        <span class="icon has-text-secondary is-medium">
                                            <i class="mdi mdi-circle mdi-24px"></i>
                                        </span>
                                        <span>
                                            {{
                                                $t(
                                                    'reference_properties_characteristics.property_characteristics'
                                                )
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="level-right">
                                <div class="level-item">
                                    <span class="icon-text">
                                        <span class="icon has-text-primary">
                                            <i class="mdi mdi-circle"></i>
                                        </span>
                                        <span>
                                            {{
                                                $t(
                                                    'reference_properties_characteristics.properties_in_the_area'
                                                )
                                            }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="chart in feature_charts"
                        :key="chart.id"
                        class="chart-box"
                    >
                        <div class="has-text-weight-bold has-text-black is-size-6">
                            {{ $t(`features.${chart.feature}`) }}
                        </div>
                        <univariate-plots
                            class="chart"
                            :feature="chart.feature"
                            :estimation_results_data="addressInfo.estimationResultsData"
                            :input_data="getFeatures"
                        />
                    </div>
                </div>

                <report-footer :page-number="6"></report-footer>
            </div>
            <div id="page-flood" class="page is-flex is-flex-direction-column">
                <div class="section content pt-5 px-5 pb-2 has-text-left">
                    <report-header />
                    <report-section
                        :name="'flood_simple'"
                        :columns="2"
                        :filter-building-type="getFeatures.f_building_type"
                        :filter-null="filterNull"
                    ></report-section>
                    <p>{{ $t('flood.explanation_1') }}</p>
                    <div class="map">
                        <re-map
                            :building-id="getFeatures.building_id"
                            :parcel-ids="getFeatures.parcel_ids"
                            :level="getFeatures.level"
                            :center="{
                                lat: getFeatures.f_lat,
                                lng: getFeatures.f_lng,
                            }"
                            :map-scene2d="get_map_scene2d"
                            :read-only="true"
                            :focus-layer="'flood'"
                            :layers-control-visible="false"
                            :options="map_options"
                            :aerial-tile-visible="false"
                            :cadastral-tile-visible="false"
                        ></re-map>
                    </div>
                </div>

                <report-footer :page-number="7"></report-footer>
            </div>
            <!--<div id="page-faq" class="page is-flex is-flex-direction-column">
                <div class="section content pt-5 px-5 pb-2 mb-0 has-text-left">
                    <report-header />
                    <div class="">
                        <report-section :name="'faq'"></report-section>
                        <p class="has-text-black has-text-weight-bold">
                            {{ $t('faq.q_incorrect_valuation') }}
                        </p>
                        <url-text :locale-key="'faq.a_incorrect_valuation'"></url-text>
                        <p class="has-text-black has-text-weight-bold">
                            {{ $t('faq.q_incorrect_characteristics') }}
                        </p>
                        <url-text
                            :locale-key="'faq.a_incorrect_characteristics'"
                        ></url-text>
                        <p class="has-text-black has-text-weight-bold">
                            {{ $t('faq.q_transaction_vs_market_value') }}
                        </p>
                        <url-text
                            :locale-key="'faq.a_transaction_vs_market_value_dvm'"
                        ></url-text>
                        <p class="has-text-black has-text-weight-bold">
                            {{ $t('faq.q_loan_accepted') }}
                        </p>
                        <p>
                            {{ $t('faq.a_loan_accepted') }}
                        </p>

                        <report-section :name="'questions_feedback'"></report-section>
                        <p>
                            {{ $t('questions_feedback.explanation_1') }}
                        </p>
                        <url-text
                            :locale-key="'questions_feedback.explanation_2'"
                        ></url-text>

                        <report-section :name="'contact_info'"></report-section>
                        <p></p>

                        <report-section :name="'disclaimer'"></report-section>
                        <p>
                            {{ $t('disclaimer.part_1') }}
                        </p>
                        <p>
                            {{ $t('disclaimer.part_2') }}
                        </p>
                        <p>
                            {{ $t('disclaimer.part_3') }}
                        </p>
                    </div>
                </div>

                <report-footer :page-number="8"></report-footer>
            </div>-->
        </div>
    </div>
</template>

<script>
//import { Previewer } from 'pagedjs'

//let paged = new Previewer()
import CostPlot from '../Avm/FormSteps/CostPlot.vue'
import ReMap from '@/components/common/Map.vue'
import ReportHeader from './Header.vue'
import ReportFooter from './Footer.vue'
import ReportSection from './Section.vue'
import Renderer from '@/shared/components/3DComponents/Renderer3D.vue'
import RotatingMarker from '@/components/common/RotatingMarker.vue'
import Step1 from '../Avm/FormSteps/Step1.vue'
import UnivariatePlots from '../Avm/FormSteps/UnivariatePlots.vue'
// import UrlText from './UrlText.vue'

import { icon } from 'leaflet'

import { mapMutations, mapGetters } from 'vuex'

const date_formatter = new Intl.DateTimeFormat('fr-BE', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    /*second: 'numeric',*/
    timeZone: 'Europe/Brussels',
})
export default {
    name: 'Report',
    components: {
        CostPlot,
        ReMap,
        ReportHeader,
        ReportFooter,
        ReportSection,
        Renderer,
        RotatingMarker,
        Step1,
        //Streetview,
        UnivariatePlots,
        // UrlText,
    },
    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Report',
            // all titles will be injected into this template
            titleTemplate: '%s | Rock.estate Valuation App',
        }
    },
    data() {
        return {
            icon: icon({
                iconUrl: `${process.env.BASE_URL}sector.svg`,
                iconSize: [40, 20],
                iconAnchor: [20, 20],
            }),
        }
    },
    computed: {
        ...mapGetters(['getFullStore', 'getFeatures', 'getDVMFeatures', 'getValuation']),
        ...mapGetters('Address', [
            'addressInfo',
            'getFeatures',
            'getView',
            'get_map_scene2d',
            'get_map_scene3d',
        ]),
        feature_charts() {
            let features = []
            if (this.getFeatures.f_building_type === 'house') {
                features = [
                    'f_parcel_area',
                    'f_living_area',
                    'f_construction_year',
                    'f_epc',
                ]
            } else {
                features = ['f_living_area', 'f_construction_year', 'f_epc']
            }
            let charts = []
            for (let i = 0; i < features.length; i++) {
                if (this.getFeatures[features[i]] !== null) {
                    charts.push({
                        id: i + 1,
                        feature: features[i],
                        detailed: features[i] == 'f_living_area',
                    })
                }
            }
            return charts
        },
        filterNull() {
            return this.$route.query.showmissing !== 'true'
        },
        packageType() {
            return this.getFeatures.renovation_cost >= 10000
                ? 'renovation_light'
                : 'classic'
        },
        bank_name() {
            return process.env.VUE_APP_LOCALE_OVERWRITE
                ? process.env.VUE_APP_LOCALE_OVERWRITE.toUpperCase()
                : 'XXXX'
        },
        formatted_date() {
            return date_formatter.format(this.getValuation.date)
        },
        map_options() {
            let static_map = this.$route.query.static === 'true'
            return {
                zoomControl: !static_map,
                attributionControl: false,
                scrollWheelZoom: false,
                doubleClickZoom: !static_map,
                boxZoom: !static_map,
                dragging: !static_map,
            }
        },
        valuer_name() {
            let valuer = this.getValuation.valuer
            function capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1)
            }
            if (typeof valuer === 'string') {
                if (valuer.includes('@')) {
                    // Extract name from email
                    return valuer
                        .split('@')[0]
                        .split('.')
                        .map(capitalizeFirstLetter)
                        .join(' ')
                } else {
                    return valuer
                }
            } else {
                return valuer
            }
        },
        avm_q50() {
            return this.getFullStore.Address.data.avm_transaction.body.estimation.asking_price_q50.toLocaleString(
                'fr-BE',
                { style: 'currency', currency: 'EUR', maximumSignificantDigits: 3 }
            )
        },
        avm_q25() {
            // To get the q25 estimation, divide q50 by the q75 relative error
            let q75_error =
                this.getFeatures.f_building_type === 'house' ? 1.21248 : 1.232495
            return (
                this.getFullStore.Address.data.avm_transaction.body.estimation
                    .asking_price_q50 / q75_error
            ).toLocaleString('fr-BE', {
                style: 'currency',
                currency: 'EUR',
                maximumSignificantDigits: 3,
            })
        },
        avm_q75() {
            // To get the q75 estimation, divide q50 by the q25 relative error
            let q25_error =
                this.getFeatures.f_building_type === 'house' ? 0.92871 : 0.956576
            return (
                this.getFullStore.Address.data.avm_transaction.body.estimation
                    .asking_price_q50 / q25_error
            ).toLocaleString('fr-BE', {
                style: 'currency',
                currency: 'EUR',
                maximumSignificantDigits: 3,
            })
        },
        num_references() {
            return this.getFullStore.Address.data.avm_transaction.body.reference_listings
                .length
        },
        reference_radius() {
            return Math.ceil(
                Math.max.apply(
                    null,
                    this.getFullStore.Address.data.avm_transaction.body.reference_listings.map(
                        (l) => l.distance / 1000
                    )
                )
            )
        },
    },
    methods: {
        ...mapMutations('Address', ['SET_MAP_SCENE3D']),
        reconstruction_value_enabled() {
            return (
                process.env.VUE_APP_RECONSTRUCTION_VALUE_ENABLED.toLowerCase() === 'true'
            )
        },
    },
}
</script>

<style lang="scss">
#renderer-3d {
    height: 150px;
    width: 100%;
}
#toc {
    top: 5.5rem;
    left: 0;
    width: 190px;
    position: fixed;
    z-index: 0;
}
.page {
    z-index: 1;
}
@page {
    size: A4;
}
body {
    background: white;
}
.cover-page {
    background: url('../../shared/assets/icons/img-skyline.svg') no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    background-size: contain;
}
.page {
    width: 210mm;
    height: 297mm;
    padding: 0cm;
    margin: 1cm auto;
    border: 1px #d3d3d3 solid;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
@media print {
    body.has-navbar-fixed-top {
        padding-top: 0;
        margin-top: 0;
    }
    #navbar,
    #toc {
        display: none;
    }
    .page {
        width: 210mm;
        height: 297mm;
        padding: 0;
        margin: 0;
        border: 0;
        box-shadow: none;
        page-break-after: always;
    }
    .page-faq {
        display: none;
    }
}
.report-footer {
    margin-top: auto;
    padding-top: auto;
    background-color: transparent;
}
.mt-10 {
    margin-top: 5rem;
}
.section-header,
.level:not(:last-child) {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
}
.section-header {
    margin-bottom: 1rem;
    margin-bottom: 1rem;
}
.chart1 {
    max-width: 940px;
    width: 100%;
    height: 150px;
    margin-bottom: 50px;
    padding-top: 45px;
    &.chart2 {
        max-width: 820px;
        transform: scale(1.3) translateY(-90px);
        margin-top: 50px;
        margin-bottom: 0px;
    }
}
.map {
    width: 100%;
    height: 600px;
}
.leaflet-transparent {
    background-color: rgba(255, 0, 0, 0);
}
.gmnoprint,
.gm-style-cc {
    display: none;
}
</style>
