<i18n>
{
    "en-BE": {
        "assessed": "Assessed",
        "reference": "Reference",
        "listing_date": "Listing date",
        "touching_sides": "Touching sides",
        "distance": "Distance",
        "price": "Price",
        "price_sqm": "Price/sqm",
        "streetnumber": "Streetnumber",
        "use_as_reference": "Use as reference",
        "add_to_remarks": "Add to remarks"
    },
    "en-FR": {
        "assessed": "Assessed",
        "reference": "Reference",
        "listing_date": "Listing date",
        "touching_sides": "Touching sides",
        "distance": "Distance",
        "price": "Price",
        "price_sqm": "Price/sqm",
        "streetnumber": "Streetnumber",
        "use_as_reference": "Use as reference",
        "add_to_remarks": "Add to remarks"
    },
    "en-NL": {
        "assessed": "Assessed",
        "reference": "Reference",
        "listing_date": "Listing date",
        "touching_sides": "Touching sides",
        "distance": "Distance",
        "price": "Price",
        "price_sqm": "Price/sqm",
        "streetnumber": "Streetnumber",
        "use_as_reference": "Use as reference",
        "add_to_remarks": "Add to remarks"
    }
}
</i18n>
<template>
    <l-map
        ref="map"
        :center="center"
        style="height: 700px; width: 100%"
        :max-zoom="18"
        :min-zoom="11"
        :zoom="15"
    >
        <l-tile-layer
            :url="aerialTileUrl"
            name="Aerial view"
            layer-type="base"
        ></l-tile-layer>
        <l-marker :lat-lng="center" />
        <l-marker
            v-for="(building, i) in population"
            :key="'building_' + i"
            :lat-lng="[building.lat, building.lng]"
            :icon="markerIcon(building)"
        >
            <l-popup class="map-popup">
                <h3 class="is-size-3 has-text-weight-bold">
                    {{ building.prijs }}
                </h3>
                <table class="is-family-primary">
                    <tr>
                        <th />
                        <th>{{ $t('assessed') }}</th>
                        <th
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`title_${j}`"
                        >
                            {{ $t('reference') }}&nbsp;{{ j + 1 }}
                        </th>
                    </tr>
                    <tr>
                        <td>{{ $t('listing_date') }}</td>
                        <td>{{ referenceDate }}</td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`date_${j}`"
                        >
                            {{ listingDate(listing.toegevoegd_num) }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t('features.f_epc') }}
                        </td>
                        <td>
                            {{ assessed.f_epc }}
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`epc_${j}`"
                        >
                            {{ listing.f_epc }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t('features.f_construction_year') }}
                        </td>
                        <td>
                            {{ assessed.f_construction_year }}
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`constr_${j}`"
                        >
                            {{ listing.f_construction_year }}
                        </td>
                    </tr>
                    <tr v-if="assessed.f_building_type !== 'apartment'">
                        <td>
                            {{ $t('touching_sides') }}
                        </td>
                        <td>
                            {{ assessed.f_touching_sides }}
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`sides_${j}`"
                        >
                            {{ listing.f_touching_sides }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t('features.f_living_area') }}
                        </td>
                        <td>
                            {{ assessed.f_living_area }}
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`living_${j}`"
                        >
                            {{ listing.f_living_area }}
                        </td>
                    </tr>
                    <tr v-if="assessed.f_building_type !== 'apartment'">
                        <td>
                            {{ $t('features.f_building_area') }}
                        </td>
                        <td>
                            {{ assessed.f_building_area }}
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`parcel_${j}`"
                        >
                            {{ listing.f_building_area }}
                        </td>
                    </tr>
                    <tr v-if="assessed.f_building_type !== 'apartment'">
                        <td>
                            {{ $t('features.f_parcel_area') }}
                        </td>
                        <td>
                            {{ assessed.f_parcel_area }}
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`parcel_${j}`"
                        >
                            {{ listing.f_parcel_area }}
                        </td>
                    </tr>
                    <tr v-if="assessed.f_building_type !== 'apartment'">
                        <td>
                            {{ $t('features.f_garden_area') }}
                        </td>
                        <td>
                            {{ assessed.f_garden_area }}
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`garden_${j}`"
                        >
                            {{ listing.f_garden_area }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t('distance') }}
                        </td>
                        <td>
                            ---
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`distance_${j}`"
                        >
                            {{ listing.distance }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t('price') }}
                        </td>
                        <td>
                            {{ price }}
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`price_${j}`"
                        >
                            {{ listing.prijs }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t('price_sqm') }}
                        </td>
                        <td>
                            {{ Math.round(price / assessed.f_living_area) }}
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`ppsqm_${j}`"
                        >
                            {{ Math.round(listing.prijs / listing.f_living_area) }}
                        </td>
                    </tr>
                    <tr>
                        <td>GMaps</td>
                        <td>
                            <a :href="googleMaps(center)" target="_blank">
                                URL
                            </a>
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`gmaps_${j}`"
                        >
                            <a
                                :href="googleMaps([listing.lat, listing.lng])"
                                target="_blank"
                            >
                                URL
                            </a>
                        </td>
                    </tr>
                    <tr v-if="assessed.f_building_type !== 'apartment'">
                        <td>
                            {{ $t('features.level') }}
                        </td>
                        <td>
                            {{ assessed.level }}
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`level_${j}`"
                        >
                            {{ listing.level }}
                        </td>
                    </tr>
                    <tr v-if="assessed.f_building_type === 'apartment'">
                        <td>
                            {{ $t('features.f_balcony_present') }}
                        </td>
                        <td>
                            {{
                                assessed.f_balcony_present
                                    ? $t('value.yes')
                                    : $t('value.no')
                            }}
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`balcony_${j}`"
                        >
                            {{
                                listing.f_balcony_present
                                    ? $t('value.yes')
                                    : $t('value.no')
                            }}
                        </td>
                    </tr>
                    <tr v-if="assessed.f_building_type === 'apartment'">
                        <td>
                            {{ $t('features.f_bedrooms') }}
                        </td>
                        <td>
                            {{ assessed.f_bedrooms }}
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`bedrooms_${j}`"
                        >
                            {{ listing.f_bedrooms }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t('streetnumber') }}
                        </td>
                        <td>
                            {{ address.streetnumber }}
                        </td>
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`bedrooms_${j}`"
                        >
                            {{ listing.streetnumber }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t('use_as_reference') }}
                        </td>
                        <td />
                        <td
                            v-for="(listing, j) in nearbyListings(building)"
                            :key="`button_${j}`"
                        >
                            <b-button
                                size="is-small"
                                type="is-primary"
                                @click="() => addListingToRemarks(listing)"
                            >
                                {{ $t('add_to_remarks') }}
                            </b-button>
                        </td>
                    </tr>
                </table>
            </l-popup>
        </l-marker>
    </l-map>
</template>
<script>
import { LMap, LMarker, LPopup } from 'vue2-leaflet'
import L from 'leaflet'
import * as d3 from 'd3'

export default {
    name: 'Map',
    components: {
        LMap,
        LMarker,
        LPopup,
    },
    props: {
        center: {
            type: Array,
            required: true,
        },
        region: {
            type: String,
            required: true,
        },
        assessed: {
            type: Object,
            required: true,
        },
        price: {
            type: Number,
            required: true,
        },
        population: {
            type: Array,
            default: () => [],
        },
        prices: {
            type: Array,
            default: () => [],
        },
        pricePerSqmArray: {
            type: Array,
            default: () => [],
        },
        address: {
            type: Object,
            required: true,
        },
        updateRemarks: {
            type: Function,
            required: true,
        },
    },
    computed: {
        aerialTileUrl() {
            return 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        },
        referenceDate() {
            return process.env.VUE_APP_ESTIM_DATA_SCHEMA_DATE
        },
    },
    methods: {
        listingDate(days) {
            const refDate = new Date(this.referenceDate)
            refDate.setDate(refDate.getDate() + days)
            return refDate.toISOString().split('T')[0]
        },
        googleMaps([lat, lng]) {
            return `https://www.google.com/maps?q=${lat},${lng}&z=19`
        },
        nearbyListings(ref) {
            const loc = L.latLng([ref.lat, ref.lng])
            const listings = this.population.filter(
                (listing) => loc.distanceTo([listing.lat, listing.lng]) <= 3
            )
            return listings
        },
        markerIcon(ref) {
            const colors = ['green', 'lightgreen', 'grey', 'orange', 'red']

            const priceTile = d3.bisect(this.prices, ref.prijs) / this.prices.length
            const pricePerSqmTile =
                d3.bisect(
                    this.pricePerSqmArray,
                    Math.round(ref.prijs / ref.f_living_area)
                ) / this.pricePerSqmArray.length

            let puckColor = 'white'
            let strokeColor = 'white'

            if (priceTile < 0.2) puckColor = colors[0]
            else if (priceTile < 0.4) puckColor = colors[1]
            else if (priceTile < 0.6) puckColor = colors[2]
            else if (priceTile < 0.8) puckColor = colors[3]
            else puckColor = colors[4]

            if (pricePerSqmTile < 0.2) strokeColor = colors[0]
            else if (pricePerSqmTile < 0.4) strokeColor = colors[1]
            else if (pricePerSqmTile < 0.6) strokeColor = colors[2]
            else if (pricePerSqmTile < 0.8) strokeColor = colors[3]
            else strokeColor = colors[4]

            const isSimilar =
                Math.abs(
                    ref.f_construction_year / this.assessed.f_construction_year - 1
                ) < 0.3 &&
                Math.abs(ref.f_epc / this.assessed.f_epc - 1) < 0.2 &&
                L.latLng(this.center).distanceTo([ref.lat, ref.lng]) < 1000

            const icon = L.divIcon({
                html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32">
                    <circle id="puck" cx="16" cy="16" r="10" fill="${puckColor}" fill-opacity="${
                    isSimilar ? 1 : 0.6
                }"/>
                    ${isSimilar &&
                        '<circle id="blue-stroke" cx="16" cy="16" r="12" fill="#ffffff00" stroke-width="4" stroke="#0000FFFF" />'}
                    <circle id="stroke" cx="16" cy="16" r="14" fill="#ffffff00" stroke-width="3" stroke="${strokeColor}" />
                </svg>`,
                iconSize: [32, 32],
                iconAnchor: [16, 16],
            })

            return icon
        },
        addListingToRemarks(ref) {
            let content = 'Reference Listing: \n'
            content += ref.address.streetname
            content += ' '
            content += ref.address.streetnumber
            content += '\n'
            content += ref.address.postalcode
            content += ' '
            content += ref.address.municipality
            content += '\n'
            content += Math.round(ref.prijs / ref.f_living_area)
            content += '€/sqm\n'

            this.updateRemarks(content)
        },
    },
}
</script>
<style lang="scss">
.leaflet-popup {
    max-width: 750px;
}

.leaflet-popup-content-wrapper {
    width: fit-content;
    max-width: 100%;
}
.leaflet-popup-content {
    width: auto !important;
    max-width: 100%;
    overflow-x: auto;
}

.map-popup {
    width: fit-content;

    table {
        overflow-x: auto;
        max-width: 100%;

        th {
            padding: 0 0.2rem;
            border: 1px solid black;
        }
        td {
            padding: 0 0.2rem;
            border: 1px solid black;

            &:first-child {
                font-weight: bold;
            }
        }
    }
}

.leaflet-div-icon {
    background: none !important;
    border: 0;
}
</style>
